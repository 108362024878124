@screen: ~"screen\0";

.twitter-list {
    max-height: 340px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.twitter-list__item {
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
    margin-bottom: 10px;
    position: relative;
}

.twitter-list__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 0 5px;
    overflow: auto;
}

.twitter-list__profile-img {
    margin-right: 10px;
    @media @screen {
        float: left;
    }
}

.twitter-list__username {
    font-size: 14px;
    font-size: 1.3rem;
    margin: 0;
    font-weight: 600;
}

.twitter-list__body {
    padding: 0 5px;
}

.twitter-list__content {
    a {
        text-decoration: underline;
        color: #2b7bb9;
        position: relative;
        z-index: 101;
    }
}

.twitter-list__img-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}



.twitter-list__img-item {
    flex: 1 0 50%;
    &:not(:only-child) {
        max-height: 65px;
        overflow: hidden;
        @media @screen {
            max-height: none;
            overflow: visible;
        }
    }
}

.twitter-list__img {
    // flex: 1 0 50%;
    width: 100%;
    // max-width: 100%;
   
}

.twitter-list__time-ago {
    margin-top: 10px;
    display: block;
    text-decoration: none;
    border-bottom: 0 !important;
    font-weight: 600;
    color: #a7a7a7;
    font-size: 1.2rem;
}