.sitemap__search {
   background-color: #e9e9e9;
   padding: 20px;
   display: flex;
   justify-content: space-between;
   margin: 15px 0 30px;
   input[type=text] {
      padding: 10px 20px;
      border: 1px solid #c2c2c2;
      border: 0;
      margin-right: 20px;
      flex: 1;
   }
}

.sitemap__search-clear {
   background-color: @c-patients;
   padding: 10px 20px;
   border-radius: 5px;
   // border: 1px solid @c-patients;
   text-decoration: none !important;
   font-weight: 600;
   &:before {
      content: '\f00d';
      font-family: 'fontAwesome';
      margin-right: 10px;
      font-size: 12px;
      font-weight: 300;
      transform: translateY(-1px);
      display: inline-block;
   }
}

.sitemap__results {
   ul {
      margin: 0;
   }
   li {
      position: relative;
      padding: 12px 0 12px 12px;
      border-bottom: 1px solid #eee;
      list-style-type: none;
      font-weight: 400;
      color: #006f94;
      &:before {
         content: '\f111';
         font-family: 'fontAwesome';
         font-size: 8px;
         margin-right: 10px;
         color: #ccc;
      }
      &.li--open {
        // padding-bottom: 0;
        border-bottom: 1px solid #eee;
        font-weight: 600;
        // margin-bottom: -1px;
        // background: lighten(#eee, 2%);
        // background: #f1f1f1;
        // &:after {
        //   content: '';
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   left: 0;
        //   height: 47px;
        //   background: fade(#eee, 70%);
        //   z-index: -1;
        // }
        ul {
          margin-bottom: -12px;
          // li.li--open {
          //   background: #e7e7e7;
          // }
        }
      }
      a {
         text-decoration: none;
         font-weight: 500;
         &:hover {
           text-decoration: underline;
         }
      }
      i {
        float: right;
        font-size: 12px;
        top: 4px;
        position: relative;
        margin-right: 20px;
        &.rotate {
          transform: scaleY(-1);
        }
      }
      ul {
         border-left: 1px solid #eee;
        //  padding-left: 10px;
         margin: 10px 0 0 20px;
        //  background-color: lighten(#eee, 5%);
         li {
            border: 0;
            padding-left: 22px;
         }
      }
   }
   a.cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

