.hr-grid {
    padding: 0;
    margin: 0 -15px;
    @media @tablet {
        // margin: 0 0 0 -20px;
        margin: 0;
    }
    div {
        outline: none;
    }
}

.hr-grid__panel-container {
    margin: 0 10px 0 -4px;
    padding: 30px 13px; 
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -9999px;
        right: -9999px;
        z-index: -1;
        display: block;
    }
    @media @tablet {
        margin: 0 -20px 0 -10px;
    }
    &.patientsandvisitors {
        background-color: @c-patients;
        &:before {
            background-color: @c-patients;
        }
    }
    &.aboutus {
        background-color: @c-about;
        &:before {
            background-color: @c-about;
        }
    }
    &.ourperformance {
        background-color: @c-performance;
        &:before {
            background-color: @c-performance;
        }
    }
    &.yourhealth {
        background-color: @c-health;
        &:before {
            background-color: @c-health;
        }
    }
    &.workingwithus {
        background-color: @c-work;
        &:before {
            background-color: @c-work;
        }
    }
}

.hr-grid__panel-title {
    padding: 20px 30px;
    margin: 0 -8px 0 -22px; 
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -9999px;
        right: -9999px;
        z-index: -1;
    }
    @media @tablet {
        margin: 0 -30px 0 -20px;
    }
    &.patientsandvisitors {
        background-color: darken(@c-patients, 10%);
        &:before {
            background-color: darken(@c-patients, 10%);
        }
        span.AltHeadingColour {
            color: darken(@c-patients, 40%);
        }
    }
    &.aboutus {
        background-color: darken(@c-about, 10%);
        &:before {
            background-color: darken(@c-about, 10%);
        }
        span.AltHeadingColour {
            color: darken(@c-about, 40%);
        }
    }
    &.ourperformance {
        background-color: darken(@c-performance, 10%);
        &:before {
            background-color: darken(@c-performance, 10%);
        }
        span.AltHeadingColour {
            color: darken(@c-performance, 40%);
        }
    }
    &.yourhealth {
        background-color: darken(@c-health, 10%);
        &:before {
            background-color: darken(@c-health, 10%);
        }
        span.AltHeadingColour {
            color: darken(@c-health, 40%);
        }
    }
    &.workingwithus {
        background-color: darken(@c-work, 10%);
        &:before {
            background-color: darken(@c-work, 10%);
        }
        span.AltHeadingColour {
            color: darken(@c-work, 40%);
        }
    }
    h1 {
        color: #FFF;
        margin: 0;
        font-size: 2.9rem;
        letter-spacing: 0;
    }
}

.hr-grid__panel {
    .container {
        padding: 0;
        max-width: 100%;
    }
    img {
        max-width: 100%;
        object-fit: cover;
    }
    .SubHeading {
        font-size: 22px;
        line-height: 1.5;
        font-weight: 700;
    }
    p {
        font-size: 1.7rem;
        line-height: 1.6;
        margin: 20px 0;
        word-wrap: break-all;
        &:first-of-type {
            margin-top: 0;
        }
        &:empty {
            margin: 0;
            display: none;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    ul {
        list-style-type: disc;
        margin: 0 0 0 20px;
        li {
            margin-bottom: 10px;
            line-height: 1.6;
            font-size: 1.7rem;
        }
    }
    a {
        color: #000 !important;
        font-weight: 600;
        text-decoration: underline;
    }
}

.hr-grid__sticky {
    position: sticky;
    top: 0;
    z-index: 100;
    &.js-is-sticky {
        left: 0;
        right: -20px;
    }
}

.hr-grid__sticky-nav {
    background-color: #eee;
    padding: 20px 30px;
    margin: 0 -8px 0 -22px;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: -9999px;
        right: -9999px;
        z-index: -1;
        background-color: #eee;
    }
    @media @tablet {
        margin: 0 -30px 0 -20px;
    }
    ul {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media @desktop {
            display: none;
        }
        &.mobile-show {
            @media @desktop {
                border-top: 2px solid #ccc;
                margin-top: 20px;
                display: block;
                position: absolute;
                background-color: #eeeeee;
                left: -4px;
                right: 0;
                width: ~"calc(100% - 6px)";
                padding: 20px;
            }
            @media @tablet {
                left: -10px;
                width: ~"calc(100% + 30px)";
            }
        }
        li {
            display: inline-block;
            // margin-right: 20px;
            border-left: 2px solid #ccc;
            // padding-left: 20px;
            padding: 0 20px;
            text-align: left;
            text-transform: lowercase;
            font-size: 1.3rem;
            // @supports (display: flex) {
            //     margin-right: 0;
            // }
            @media @desktop {
                text-align: left;
            }
            &:first-of-type {
                border: 0;
                padding-left: 0;
            }
            @media @desktop {
                display: block;
                width: 100%;
                border: 0;
                padding: 0;
                margin-bottom: 10px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                br {
                    display: inline;
                    content: ' ';
                    clear: none;
                }
            }
            a {
                font-weight: 600;
            }
            i {
                font-size: 2.5rem;
            }
        }
    }
}

.hr-grid__sticky-nav-hidden {
    font-size: 2rem;
    display: none;
    cursor: pointer;
    &:before {
        content: '\f0c9';
        font-family: 'fontAwesome';
        margin-right: 10px;
    }
    &.active {
        &:before {
            content: '\f00d';
        }
    }
    @media @desktop {
        display: block;
    }
}

h1.grid {
    display: none;
    margin: 20px;
    @media @tablet {
        margin: 20px 0;
    }
}
