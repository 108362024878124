.loading-container {
   padding: 100px 0;
   position: relative;
   width: 200px;
   z-index: 1;
   p {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 1rem;
  }
}

.loading-spinner {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -35px 0 0 -35px;
  border:15px solid #CCC;
  border-right-color: transparent;
  border-radius: 50%;
  transition: all ease .5s;
  animation: spinner linear 1s;
  animation-iteration-count: infinite;
  transition-delay: 0s;
  z-index: 9;
  &.active {
    opacity: 1;
    transition-delay: .7s;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}