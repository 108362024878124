// compress: true, sourceMap: true
@desktop-wide: ~"only screen and (max-width : 1200px)";
@desktop-wide-min: ~"only screen and (min-width : 1101px)";
@desktop: ~"only screen and (max-width : 992px)";
@desktop-min: ~"only screen and (min-width : 993px)";
@tablet: ~"only screen and (max-width : 768px)";
@mobile: ~"only screen and (max-width : 480px)";
body.ie {
	font-size: 14px;
}

body {
	overflow-x: hidden;
}

.wrapper {
	// overflow-x: hidden;
}

.ie .textpanel a p,
.ie .half-image-half-text p,
.ie .half-image-half-text span.para {
	font-size: 24px;
	color: #1e1d1b;
	font-weight: normal;
	font-weight: 300;
	font-style: normal;
	font-family: "nimbus-sans-n3", "nimbus-sans";
}

.ie .textpanel A SPAN.para,
.content1_default .panel-title,
.promoarea .promo P,
.inner H1,
.navintro H4 {
	font-family: "nimbus-sans-n3", "nimbus-sans";
}

.col-xxs-1,
.col-xxs-2,
.col-xxs-3,
.col-xxs-4,
.col-xxs-5,
.col-xxs-6,
.col-xxs-7,
.col-xxs-8,
.col-xxs-9,
.col-xxs-10,
.col-xxs-11,
.col-xxs-12 {
	min-height: 1px;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}

.displayNone {
	display: none;
}

.displayBlock {
	display: block;
}

html body div .debug {
	color: red;
	font-weight: bold;
	display: block;
	width: 100%;
	clear: both;
}

@media (max-width: 400px) {
	.col-xxs-1,
	.col-xxs-2,
	.col-xxs-3,
	.col-xxs-4,
	.col-xxs-5,
	.col-xxs-6,
	.col-xxs-7,
	.col-xxs-8,
	.col-xxs-9,
	.col-xxs-10,
	.col-xxs-11 {
		float: left;
	}
	.col-xxs-1 {
		width: 8.333333333333332%;
	}
	.col-xxs-2 {
		width: 16.666666666666664%;
	}
	.col-xxs-3 {
		width: 25%;
	}
	.col-xxs-4 {
		width: 33.33333333333333%;
	}
	.col-xxs-5 {
		width: 41.66666666666667%;
	}
	.col-xxs-6 {
		width: 50%;
	}
	.col-xxs-7 {
		width: 58.333333333333336%;
	}
	.col-xxs-8 {
		width: 66.66666666666666%;
	}
	.col-xxs-9 {
		width: 75%;
	}
	.col-xxs-10 {
		width: 83.33333333333334%;
	}
	.col-xxs-11 {
		width: 91.66666666666666%;
	}
	.col-xxs-12 {
		width: 100%;
		float: left;
	}
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
	padding-left: 18px;
	padding-right: 18px;
}

/* Patients and Visitors - Blue */

/* About Us - Yellow */

/* Our Performance - Green */

/* Your Health - Pink */

/* Working With Us - Blue */

/* Your Health - Blue */

/* Craig - This is just a random grey for testing */

.faq {
	min-height: initial;
}

.faq .FAQAnswerCaption {
	padding: 10px 10px 0;
	/*font-size: 1.2em;*/
	font-weight: 300;
	padding-bottom: 7px;
}

.faq .FAQAnswerFull {
	display: none;
	background-color: #fef2eb;
	padding: 10px;
	h1, h2, h3, h4, h5 {
		padding-left: 0;
	}
}

.faq .faqTitleWrapper h4 {
	min-height: inherit;
	padding: 0 50px 0 60px;
	background-image: none;
	margin-bottom: 0;
	font-weight: 500;
}

.faq .faqTitleWrapper i {
	position: absolute;
	top: 20px;
	left: 15px;
	font-size: 21px;
}

.faq .faqTitleWrapper a {
	color: #323137;
	padding: 15px 0;
	width: 100%;
	display: block;
}

.faq .faqTitleWrapper a:after {
	content: "\f107";
	font-family: FontAwesome;
	float: right;
	right: 20px;
	position: relative;
}

/*.faq .active h4 {
  background-color: #F48132;
}*/

.faq .active a:after {
	content: "\f105";
}

/* Panel heights */

.panel-short {
	height: 150px;
	margin-bottom: 17px;
}

.homepage .panel {
	height: 340px;
}

.textpanel {
	height: 340px;
	background: #e9e9e9;
}

/* New Panel / Promo */

.promoarea {
	padding-right: 0;
	overflow: hidden;
	margin-bottom: 17px;
}

.promoarea .promo p {
	font-size: 24px;
	font-weight: 300;
	padding: 15px;
}

.promoarea .promo a {
	width: 100%;
	height: 100%;
	display: block;
	text-decoration: none;
}

.childList .promo,
.quickLinks .promo {
	height: 150px;
	border-top: 3px solid transparent;
}

.childList .AltColour .promo,
.quickLinks .AltColour .promo {
	height: 150px;
}

.quickLinks .promoarea .promo p {
	line-height: 1.2em;
}

/*Section overrides */

.content_patientsandvisitors .promo,
.content_patientsandvisitors .quickLinks .promo {
	background: #a8d8ef;
}

.content_aboutus .promo,
.content_aboutus .childList .promo,
.content_aboutus .quickLinks .promo {
	background: #ffc04d;
}

.content_ourperformance .promo,
.content_ourperformance .childList .promo,
.content_ourperformance .quickLinks .promo {
	background: #9bd6b6;
}

.content_workingwithus .promo,
.content_workingwithus .childList .promo,
.content_workingwithus .quickLinks .promo {
	background: #80b7c9;
}

.content_yourhealth .promo,
.content_yourhealth .childList .promo,
.content_yourhealth .quickLinks .promo {
	background: #f28db8;
}

.content_homepage .promo,
.content_default .promo,
.content1_default .promo,
.content_default .childList .promo,
.content_default .quickLinks .promo {
	background: #00a8e1;
}

.content_patientsandvisitors .childList .AltColour .promo,
.content_patientsandvisitors .quickLinks .AltColour .promo,
.content_patientsandvisitors.AltColour .promo {
	border-top: 3px solid #a8d8ef;
	background: #e9e9e9;
}

.content_aboutus .childList .AltColour .promo,
.content_aboutus .quickLinks .AltColour .promo,
.content_aboutus.AltColour .promo {
	border-top: 3px solid #ffc04d;
	background: #e9e9e9;
}

.content_ourperformance .childList .AltColour .promo,
.content_ourperformance .quickLinks .AltColour .promo,
.content_ourperformance.AltColour .promo {
	border-top: 3px solid #9bd6b6;
	background: #e9e9e9;
}

.content_workingwithus .childList .AltColour .promo,
.content_workingwithus .quickLinks .AltColour .promo,
.content_workingwithus.AltColour .promo {
	border-top: 3px solid #80b7c9;
	background: #e9e9e9;
}

.content_yourhealth .childList .AltColour .promo,
.content_yourhealth .quickLinks .AltColour .promo,
.content_yourhealth.AltColour .promo {
	border-top: 3px solid #f28db8;
	background: #e9e9e9;
}

.content_default .childList .AltColour .promo,
.content_default .quickLinks .AltColour .promo,
.content_default.AltColour .promo {
	border-top: 3px solid #00a8e1;
	background: #e9e9e9;
}

/* C2 - FAQs */

.faq {
	min-height: initial;
}

.faq .FAQAnswerFull {
	display: none;
	background-color: #d3e9f3;
	padding: 10px;
}

.faq .faqTitleWrapper {
	background-color: #ddf1fa;
}

.faq .faqTitleWrapper.active {
	background-color: #00a8e1;
}

.faq .faqTitleWrapper h4 {
	min-height: inherit;
	/* padding: 0 0 0 10px; */
	background-image: none;
	margin-bottom: 0;
	border-bottom: 2px solid #00a8e1;
	font-size: 1.4em;
}

.faq .faqTitleWrapper h4:hover a span {
	text-decoration: underline
}

.faq .faqTitleWrapper h4 span {
	display: block;
	width: 90%;
}

.faq .faqTitleWrapper a {
	color: #323137;
	padding: 8px 0;
	width: 100%;
	display: block;
	text-decoration: none;
}

.faq .faqTitleWrapper a:after {
	content: "\f107";
	font-family: FontAwesome;
	float: right;
	right: 20px;
	top: 20px;
	position: absolute;
}

.faq .active h4 a span {
	text-decoration: underline;
}

.faq .active a:after {
	content: "\f105";
}

/* Craig - FAQ background colours */

// Colour variables
// @c-patients: #206a9a;
// @c-about: #e05800;
// @c-performance: #37ae6d;
// @c-health: #e51b72;
// @c-work: #006f94;
@c-patients: #a8d8ef;
@c-about: #ffc04d;
@c-performance: #9bd6b6;
@c-health: #f28db8;
@c-work: #80b7c9;
.faq {
	&.patientsandvisitors {
		.faqTitleWrapper {
			background: @c-patients;
			h4 {
				border-bottom: 2px solid darken(@c-patients, 20%);
				a {
					color: #000;
				}
			}
			&.active {
				background: darken(@c-patients, 20%);
				color: #FFF;
				h4 a {
					color: #FFF;
				}
			}
		}
		.FAQAnswerFull {
			background: lighten(@c-patients, 10%);
		}
	}
	&.aboutus {
		.faqTitleWrapper {
			background: @c-about;
			h4 {
				border-bottom: 2px solid darken(@c-about, 20%);
				a {
					color: #000;
				}
			}
			&.active {
				background: darken(@c-about, 20%);
				color: #FFF;
				h4 a {
					color: #FFF;
				}
			}
		}
		.FAQAnswerFull {
			background: lighten(@c-about, 25%);
		}
	}
	&.ourperformance {
		.faqTitleWrapper {
			background: @c-performance;
			h4 {
				border-bottom: 2px solid darken(@c-performance, 20%);
				a {
					color: #000;
				}
			}
			&.active {
				background: darken(@c-performance, 20%);
				color: #FFF;
				h4 a {
					color: #FFF;
				}
			}
		}
		.FAQAnswerFull {
			background: lighten(@c-performance, 20%);
		}
	}
	&.workingwithus {
		.faqTitleWrapper {
			background: @c-work;
			h4 {
				border-bottom: 2px solid darken(@c-work, 20%);
				a {
					color: #000;
				}
			}
			&.active {
				background: darken(@c-work, 20%);
				color: #FFF;
				h4 a {
					color: #FFF;
				}
			}
		}
		.FAQAnswerFull {
			background: lighten(@c-work, 20%);
		}
	}
	&.yourhealth {
		.faqTitleWrapper {
			background: @c-health;
			h4 {
				border-bottom: 2px solid darken(@c-health, 20%);
				a {
					color: #000;
				}
			}
			&.active {
				background: darken(@c-health, 20%);
				color: #FFF;
				h4 a {
					color: #FFF;
				}
			}
		}
		.FAQAnswerFull {
			background: lighten(@c-health, 20%);
		}
	}
}

/* C2 - Can you check */

#serchNews p {
	font-size: 0.8em;
}

.panel-short p {
	font-size: 1.6em;
	padding: 0 0 10px;
}

.mobile .nav li a {
	line-height: 2em;
	float: left;
	border-top: 1px solid #fff;
	padding: 0.5em;
}

.mobile .nav li.nav_home a {
	color: #000;
	border-top: 1px solid #b1b2b2;
	font-weight: 700;
	font-size: 1em;
	padding: 0.5em;
}

.mobile .nav li.nav_patientsandvisitors a {
	background: #a8d8ef;
	color: #000;
	font-weight: 700;
	font-size: 1em;
	padding: 0.5em;
}

.mobile .nav li.nav_patientsandvisitors ul a {
	background: #a8d8ef;
	color: #000;
	font-weight: 300;
	font-size: 0.9em;
	padding: 0.5em;
}

.mobile .nav li.nav_aboutus a {
	background: #ffc04d;
	color: #000;
	font-weight: 700;
	font-size: 1em;
	padding: 0.5em;
}

.mobile .nav li.nav_aboutus ul a {
	background: #ffc04d;
	color: #000;
	font-weight: 300;
	font-size: 0.9em;
	padding: 0.5em;
}

.mobile .nav li.nav_ourperformance a {
	background: #9bd6b6;
	color: #000;
	font-weight: 700;
	font-size: 1em;
	padding: 0.5em;
}

.mobile .nav li.nav_ourperformance ul a {
	background: #9bd6b6;
	color: #000;
	font-weight: 300;
	font-size: 0.9em;
	padding: 0.5em;
}

.mobile .nav li.nav_workingwithus a {
	background: #80b5c7;
	color: #000;
	font-weight: 700;
	font-size: 1em;
	padding: 0.5em;
}

.mobile .nav li.nav_workingwithus ul a {
	background: #80b7c9;
	color: #000;
	font-weight: 300;
	font-size: 0.9em;
	padding: 0.5em;
}

.mobile .nav li.nav_yourhealth a {
	background: #f08bb6;
	color: #000;
	font-weight: 700;
	font-size: 1em;
	padding: 0.5em;
}

.mobile .nav li.nav_yourhealth ul a {
	background: #f08bb6;
	color: #000;
	font-weight: 300;
	font-size: 0.9em;
	padding: 0.5em;
}

.mobile .nav li ul li a {
	text-indent: 20px;
}

.mobile.nav>li.hover>ul {
	float: left;
	position: relative;
	z-index: 10;
}

.para {
	width: 100%;
}

.panels h3 {
	margin-left: 20px;
}

.childList {
	margin-top: 18px;
	padding-left: 0;
	padding-right: 0;
}

.childList .panel-holder .textpanel {
	background: transparent;
}

.quickLinks {
	padding-left: 0;
	padding-right: 0;
	margin-top: 30px;
}

.panels {
	padding-left: 0;
	padding-right: 0;
}

.quickPanels .panel {
	background: #e9e9e9;
}

.largePanels .panel {
	height: 350px;
}

.panels .searchById p {
	padding: 10px 10px 0 10px;
}

/* Short panels */

.largePanels.shortPanels .panel {
	height: 190px;
}

.shortPanels .half-image-half-text span.panelicon {
	height: 82px;
}

.shortPanels .half-image-half-text span.panelicon .fa {
	top: 7%;
}

.shortPanels .sidecontent {
	min-height: 190px;
}

.shortPanels .panel.panel-default.panel-data.TwitterPanel.twitter {
	overflow-y: scroll;
}

.largePanels.shortPanels .panel .readmore {
	display: none;
}

.panels.shortPanels .searchById form {
	padding: 10px 10px 0 10px;
}

.panels .searchById form {
	padding: 20px 10px 10px 10px;
}

.content1_patientsandvisitors .quicknav {
	border: 2px solid #a8d8ef;
}

.content1_patientsandvisitors .panel-title {
	background: #a8d8ef;
}

.content1_patientsandvisitors a {
	color: #206a99;
}

.content1_patientsandvisitors p a {
	color: #206a99;
}

.content_patientsandvisitors ul.quicklink li {
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content1_patientsandvisitors ul.quicklink li {
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content_patientsandvisitors ul.quicklink li ul li {
	list-style-type: none;
	padding-left: 15px;
	border-bottom: none;
}

ul.quicklink li ul {
	border-top: 1px solid #e0e0e0;
}

ul.quicklink li ul li:last-child {
	border-bottom: none;
}

.content_patientsandvisitors .half-image-half-text {
	background: #a8d8ef;
}

.content_patientsandvisitors .panel-section-top-strip {
	background: #a8d8ef;
}

.content_patientsandvisitors .overlay {
	background: url(/img/patientoverlay.png) repeat;
}

.panel-holder.unknown .overlay {
	background: url(/img/unknownoverlay.png) repeat;
}

.content_patientsandvisitors .fa {
	color: #206a9a;
}

.content_patientsandvisitors .small-panel-alt {
	background: #a8d8ef;
}

.content_patientsandvisitors .small-panel-standard {
	background: #e9e9e9;
}

.content_patientsandvisitors .textpanel a.readmore {
	color: #206a99;
}

.content1_patientsandvisitors .childList .promo {
	background: #a8d8ef;
	color: #206a99;
}

.content1_patientsandvisitors .childList .promo a {
	color: #206a99;
}

.content1_patientsandvisitors .AltColour .promo {
	border-top: 3px solid #a8d8ef;
	background: #e9e9e9;
}

.content_patientsandvisitors .AltColour .promo {
	border-top: 3px solid #a8d8ef;
	background: #e9e9e9;
}

.content1_patientsandvisitors .topLevel.active {
	background: #a8d8ef;
}

.content_patientsandvisitors.AltColour .promo {
	border-top: 3px solid #a8d8ef;
	background: #e9e9e9;
}

.content1_patientsandvisitors .childList .AltColour .promo {
	border-top: 3px solid #a8d8ef;
	background: #e9e9e9;
}

.accContent .content_patientsandvisitors .quicknav {
	border: 2px solid #a8d8ef;
}

.accContent .content_patientsandvisitors .panel-title {
	background: #a8d8ef;
}

.content1_patientsandvisitors .ContentDivide {
	border-top: 1px solid #206a9a;
}

.content1_patientsandvisitors .intro {
	font-size: 1.2em;
	color: #206a9a;
	font-weight: 300;
}

.content1_aboutus .quicknav {
	border: 2px solid #ffc04d;
}

.content1_aboutus .panel-title {
	background: #ffc04d;
}

.content1_aboutus a {
	color: #e05800;
}

.content1_aboutus p a {
	color: #e05800;
}

.content_aboutus ul.quicklink li {
	background: url(/img/aboutaarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content1_aboutus ul.quicklink li {
	background: url(/img/aboutaarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content_aboutus .half-image-half-text {
	background: #ffc04d;
}

.content_aboutus .panel-section-top-strip {
	background: #ffc04d;
}

.content1_aboutus h3.paneltitle {
	background: #ffc04d;
	/* url(/img/FindNearest.png) no-repeat; */
	background-position: right;
	color: black;
}

.content_aboutus .overlay {
	background: url(/img/aboutoverlay.png) repeat;
}

.content_aboutus .fa {
	color: #e05800;
}

.content_aboutus .small-panel-alt {
	background: #ffc04d;
}

.content_aboutus .small-panel-standard {
	background: #e9e9e9;
}

.content_aboutus .textpanel a.readmore {
	color: #e05800;
}

.content_aboutus .panel a.readmore {
	color: #e05800;
}

.content_aboutus .panel .readmore {
	color: #e05800;
}

.content1_aboutus .childList .promo {
	background: #ffc04d;
	color: #e05800;
}

.content1_aboutus .childList .promo a {
	color: #e05800;
}

.content_aboutus .AltColour .promo {
	border-top: 3px solid #ffc04d;
	background: #e9e9e9;
}

.content1_aboutus .topLevel.active {
	background: #ffc04d;
}

.content1_aboutus .childList .AltColour .promo {
	border-top: 3px solid #ffc04d;
	background: #e9e9e9;
}

.accContent .content_aboutus .quicknav {
	border: 2px solid #ffc04d;
}

.accContent .content_aboutus .panel-title {
	background: #ffc04d;
}

.content1_aboutus .ContentDivide {
	border-top: 1px solid #e05800;
}

.content1_aboutus .intro {
	font-size: 1.2em;
	color: #e05800;
	font-weight: 300;
}

.news .panel-content-container ul.quicklink {
	margin: 1px 0 0 8px;
	list-style-type: none;
	width: 92%;
}

.news .panel-content-container ul.quicklink li {
	background: url(/img/aboutaarrow.png) no-repeat;
	background-position: 100% 10px;
}

ul.newsLatest li a.newsDate {
	font-size: 11px;
	font-family: arial;
	position: relative;
	display: inline;
	top: -8px;
	color: #e05800;
}

img.newsImage {
	padding: 0 0 14px 14px;
	max-width: 300px;
	max-height: 200px;
}

.content1_ourperformance .quicknav {
	border: 2px solid #9bd6b6;
}

.content1_ourperformance .panel-title {
	background: #9bd6b6;
}

.content1_ourperformance a {
	color: #006633;
}

.content1_ourperformance p a {
	color: #006633;
}

.content_ourperformance ul.quicklink li {
	background: url(/img/performancearrow.png) no-repeat;
	background-position: 100% 10px;
}

.content1_ourperformance ul.quicklink li {
	background: url(/img/performancearrow.png) no-repeat;
	background-position: 100% 10px;
}

.content_ourperformance .half-image-half-text {
	background: #9bd6b6;
}

.content_ourperformance .panel-section-top-strip {
	background: #9bd6b6;
}

.content_ourperformance .overlay {
	background: url(/img/performanceoverlay.png) repeat;
}

.content_ourperformance .fa {
	color: #37ae6d;
}

.content_ourperformance .small-panel-alt {
	background: #9bd6b6;
}

.content_ourperformance .small-panel-standard {
	background: #e9e9e9;
}

.content_ourperformance .textpanel a.readmore {
	color: #006633;
}

.content1_ourperformance .childList .promo {
	background: #9bd6b6;
	color: #006633;
}

.content1_ourperformance .childList .promo a {
	color: #006633;
}

.content_ourperformance .AltColour .promo {
	border-top: 3px solid #9bd6b6;
	background: #e9e9e9;
}

.content1_ourperformance .topLevel.active {
	background: #9bd6b6;
}

.content1_ourperformance .childList .AltColour .promo {
	border-top: 3px solid #9bd6b6;
	background: #e9e9e9;
}

.accContent .content_ourperformance .quicknav {
	border: 2px solid #9bd6b6;
}

.accContent .content_ourperformance .panel-title {
	background: #9bd6b6;
}

.content1_ourperformance .ContentDivide {
	border-top: 1px solid #37ae6d;
}

.content1_ourperformance .intro {
	font-size: 1.2em;
	color: #37ae6d;
	font-weight: 300;
}

.content1_workingwithus .quicknav {
	border: 2px solid #80b7c9;
}

.content1_workingwithus .panel-title {
	background: #80b7c9;
}

.content1_workingwithus a {
	color: #006f94;
}

.content1_workingwithus p a {
	color: #006f94;
}

.content_workingwithus ul.quicklink li {
	background: url(/img/healtharrow.png) no-repeat;
	background-position: 100% 10px;
}

.content1_workingwithus ul.quicklink li {
	background: url(/img/healtharrow.png) no-repeat;
	background-position: 100% 10px;
}

.content_workingwithus .half-image-half-text {
	background: #80b7c9;
}

.content_workingwithus .panel-section-top-strip {
	background: #80b7c9;
}

.content_workingwithus .overlay {
	background: url(/img/healthoverlay.png) repeat;
}

.content_workingwithus .fa {
	color: #006f94;
}

.content_default .fa {
	color: #006b8f;
}

.content_workingwithus .small-panel-alt {
	background: #80b7c9;
}

.content_workingwithus .small-panel-standard {
	background: #e9e9e9;
}

.content_workingwithus .textpanel a.readmore {
	color: #006f94;
}

.content1_workingwithus .childList .promo {
	background: #80b7c9;
	color: #006f94;
}

.content1_workingwithus .childList .promo a {
	color: #006f94;
}

.content_workingwithus .AltColour .promo {
	border-top: 3px solid #80b7c9;
	background: #e9e9e9;
}

.content1_workingwithus .topLevel.active {
	background: #80b7c9;
}

.content1_workingwithus .childList .AltColour .promo {
	border-top: 3px solid #80b7c9;
	background: #e9e9e9;
}

.accContent .content_workingwithus .quicknav {
	border: 2px solid #80b7c9;
}

.accContent .content_workingwithus .panel-title {
	background: #80b7c9;
}

.content1_workingwithus .ContentDivide {
	border-top: 1px solid #006f94;
}

.content1_workingwithus .intro {
	font-size: 1.2em;
	color: #006f94;
	font-weight: 300;
}

.content1_yourhealth .quicknav {
	border: 2px solid #f28db8;
}

.content1_yourhealth .panel-title {
	background: #f28db8;
}

.content1_yourhealth a {
	color: #e51b72;
}

.content1_yourhealth p a {
	color: #e51b72;
}

.content1_yourhealth ul.quicklink li {
	background: url(/img/workingarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content_yourhealth ul.quicklink li {
	background: url(/img/workingarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content_yourhealth .half-image-half-text {
	background: #f28db8;
}

.content_yourhealth .panel-section-top-strip {
	background: #f28db8;
}

.content_yourhealth .overlay {
	background: url(/img/workingoverlay.png) repeat;
}

.content_yourhealth .fa {
	color: #e51b72;
}

.content_yourhealth .small-panel-alt {
	background: #f28db8;
}

.content_yourhealth .small-panel-standard {
	background: #e9e9e9;
}

.content_yourhealth .textpanel a.readmore {
	color: #e51b72;
}

.content1_yourhealth .childList .promo {
	background: #f28db8;
	color: #e51b72;
}

.content1_yourhealth .childList .promo a {
	color: #e51b72;
}

.content_yourhealth .AltColour .promo {
	border-top: 3px solid #f28db8;
	background: #e9e9e9;
}

.content1_yourhealth .topLevel.active {
	background: #f28db8;
}

.content1_yourhealth .childList .AltColour .promo {
	border-top: 3px solid #f28db8;
	background: #e9e9e9;
}

.accContent .content_yourhealth .quicknav {
	border: 2px solid #f28db8;
}

.accContent .content_yourhealth .panel-title {
	background: #f28db8;
}

.content1_yourhealth .ContentDivide {
	border-top: 1px solid #e51b72;
}

.content1_yourhealth .intro {
	font-size: 1.2em;
	color: #e51b72;
	font-weight: 300;
}

.content1_default .quicknav {
	border: 2px solid #00a8e1;
}

.content1_default .panel-title {
	background: #00a8e1;
}

.content1_default a {
	color: #002d73;
}

.content_default a {
	color: #002d73;
}

.content1_default p a {
	color: #002d73;
}

.content_default ul.quicklink li {
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content1_default ul.quicklink li {
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: 100% 10px;
}

.content_homepage .fa {
	color: #006f94;
}

.content_default .half-image-half-text {
	background: #00a8e1;
}

.content_default .panel-section-top-strip {
	background: #00a8e1;
}

.content_default .overlay {
	background: url(/img/patientoverlay.png) repeat;
}

.content_default .textpanel a.readmore {
	color: #002d73;
}

.content_default .childList .promo {
	background: #00a8e1;
	color: #e51b72;
}

.content_default .childList .promo a {
	color: #002d73;
}

.content_default .ContentDivide {
	border-top: 1px solid #002d73;
}

.content_default .intro {
	font-size: 1.2em;
	color: #002d73;
	font-weight: 300;
}

.ContentDivide {
	font-weight: bold;
	padding-top: 5px;
	width: 100%;
	float: left;
}

.detailheader {
	padding-top: 6px;
	border-top: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	padding-left: 0;
	float: left;
	margin-bottom: 15px;
	min-height: 150px;
	font-size: 1em;
}

.detailheader:first-child {
	margin-left: 0;
}

.detailheader strong {
	width: 100%;
	float: left;
	font-weight: 700;
}

.sidecolumn .twitter div.panel-title a {
	color: #fff;
}

.sidecolumn .news div.panel-title a {
	color: #000;
}

.newsholder a {
	color: #e05800;
}

.promoarea .promo .fa {
	position: absolute;
	right: 10px;
	bottom: 10px;
	background-color: #ececec;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	border-radius: 50%;
	font-size: 22px; // color: #FFF;
	img {
		transform: translateY(-2px);
	}
}

// promo area override
.content1_patientsandvisitors {
	.promoarea.AltColour .promo .fa {
		background-color: @c-patients;
	}
}

.content1_aboutus {
	.promoarea.AltColour .promo .fa {
		background-color: @c-about;
	}
}

.content1_ourperformance {
	.promoarea.AltColour .promo .fa {
		background-color: @c-performance;
	}
}

.content1_yourhealth {
	.promoarea.AltColour .promo .fa {
		background-color: @c-health;
	}
}

.content1_workingwithus {
	.promoarea.AltColour .promo .fa {
		background-color: @c-work;
	}
}

// quick links fix / override
.quickLinks {
	.content_patientsandvisitors {
		.promo {
			background-color: @c-patients;
		}
		&.AltColour {
			.promo {
				background-color: #e9e9e9;
				border-top: 3px solid @c-patients;
				.fa {
					background-color: @c-patients;
				}
			}
		}
	}
	.content_ourperformance {
		.promo {
			background-color: @c-performance;
		}
		&.AltColour {
			.promo {
				background-color: #e9e9e9;
				border-top: 3px solid @c-performance;
				.fa {
					background-color: @c-performance;
				}
			}
		}
	}
	.content_aboutus {
		.promo {
			background-color: @c-about;
		}
		&.AltColour {
			.promo {
				background-color: #e9e9e9;
				border-top: 3px solid @c-about;
				.fa {
					background-color: @c-about;
				}
			}
		}
	}
	.content_yourhealth {
		.promo {
			background-color: @c-health;
		}
		&.AltColour {
			.promo {
				background-color: #e9e9e9;
				border-top: 3px solid @c-health;
				.fa {
					background-color: @c-health;
				}
			}
		}
	}
	.content_workingwithus {
		.promo {
			background-color: @c-work;
		}
		&.AltColour {
			.promo {
				background-color: #e9e9e9;
				border-top: 3px solid @c-work;
				.fa {
					background-color: @c-work;
				}
			}
		}
	}
	.content_default {
		.promo {
			background-color: #00a8e1;
		}
		&.AltColour {
			.promo {
				background-color: #e9e9e9;
				border-top: 3px solid #00a8e1;
				.fa {
					background-color: #00a8e1;
				}
			}
		}
	}
}

.overlay {
	position: absolute;
	bottom: 20px;
	width: 100%;
	padding-right: 17px;
	float: left;
	display: block;
}

.container .jumbotron {
	padding: 15px;
}

a.readmore {
	padding: 10px 20px;
	text-decoration: underline;
	display: block;
}

.half-image-half-text {
	font-size: 0.7em;
}

.panel-title {
	padding: 6px 0 5px 16px;
	font-weight: 700;
	letter-spacing: 0;
	font-size: 17px;
	text-transform: capitalize;
	margin-top: 0;
	margin-bottom: 0;
}

.sidecolumn .quicknav div.panel-title {
	padding: 6px 0 5px 16px;
}

.sidecolumn div.panel-title a {
	color: #000;
}

.news .panel-title {
	background: #ffc04d url(/img/newstitle.png) no-repeat;
	background-position: right;
	padding: 10px 0 10px 15px;
}

.twitter .panel-title {
	background: #07a7e3 url(/img/twittertitle.png) no-repeat;
	background-position: right;
	padding: 10px 0 10px 15px;
}

.news .panel-title a {
	color: #000;
	font-weight: 500;
	display: block;
}

.twitter .panel-title a {
	color: #fff;
	font-weight: 500;
}

.content1_patientsandvisitors ul.quicklink li a.active {
	color: #206a9a;
}

.content1_aboutus ul.quicklink li a.active {
	color: #e05800;
}

.content1_yourhealth ul.quicklink li a.active {
	color: #e51b72;
}

.content1_workingwithus ul.quicklink li a.active {
	color: #006f94;
}

.content1_ourperformance ul.quicklink li a.active {
	color: #37ae6d;
}

a.panelWrapperLink {
	display: block;
	width: 100%;
	height: 100%;
}

.textpanel a,
.half-image-half-text a {
	font-size: 14px;
	width: 100%;
	text-decoration: none;
}

.textpanel a.readmore,
span.readmore {
	display: block;
	padding: 10px 20px;
	text-decoration: underline;
	position: absolute;
	left: 20px;
	bottom: 30px;
}

.textpanel a p,
.textpanel a span.para,
.half-image-half-text p,
.half-image-half-text span.para {
	font-size: 1.8em;
	padding: 10px 20px;
	line-height: 1.1em;
	text-decoration: none;
	font-weight: 300;
	margin-bottom: 0;
	float: left;
	color: black;
}

.inspireSearch span.para {
	padding: 10px;
	font-size: 13px;
	margin-bottom: 0;
	display: block;
}

.inspireSearch span.para label {
	display: block;
	width: 80px;
	float: left;
	height: 26px;
	margin-bottom: 0;
}

.inspireSearch span.para input,
.inspireSearch span.para select {
	height: 26px;
	width: 130px;
}

/* C2 - tried to add icons - May be a complete disaster */

.half-image-half-text span.panelicon {
	display: block;
	width: 100%;
	height: 180px;
}

.half-image-half-text span.panelicon .fa {
	position: absolute;
	top: 30%;
	left: 13%;
}

.half-image-half-text span.divider {
	border-bottom: 2px solid white;
	display: block;
	width: 85%;
	margin: 0 auto;
}

.half-image-half-text span.para {
	color: black;
	display: block;
}

.textpanel a img {
	padding: 0;
}

.textpanel p {
	font-size: 1.25em;
	padding: 10px 20px;
	color: #1e1d1b;
	line-height: 1.1em;
}

ul.quicklink {
	margin: 1px 17px;
	list-style-type: none;
	font-size: 0.8em;
	width: 88%;
}

ul.quicklink li {
	border-bottom: 1px solid #e0e0e0;
	line-height: 1.8em;
	width: 100%;
	font-size: 15px;
}

ul.quicklink li a {
	color: #000;
	display: block;
	width: 100%;
	line-height: 1.22em;
	padding: 4px 10px 4px 0;
}

ul.quicklink ul {
	list-style-type: none;
	padding-left: 15px;
}

p.copyright {
	font-size: 12px;
}

.quicklinks {
	padding-left: 0;
	padding-right: 0;
}

.boldText {
	font-family: "nimbus-sans";
	font-weight: 700;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

body {
	font-size: 1.6em;
	font-family: "nimbus-sans";
	color: #000;
	letter-spacing: 0;
}

p {
	font-size: 0.85em;
	color: #000;
}

.Siteholder {
	margin: 0 auto;
}

.inner {
	font-weight: 300;
}

.inner .importedcontent a {
	font-weight: 700;
}

.inner p a {
	font-weight: 700;
}

ul.panelListView li {
	width: 50%;
}

h1.title a {
	color: #002d73;
}

h1.title a:hover {
	color: #002d73;
}

h1 {
	color: #000;
	font-size: 240%;
	letter-spacing: -2px;
	margin-left: 0;
	padding-left: 0;
	padding-right: 0;
	margin-top: 32px;
	font-weight: 300;
}

h2 {
	font-size: 1.9em;
	padding-left: 0;
	font-weight: 700;
}

h3 {
	margin: 0;
	padding-left: 0;
	margin-bottom: 10px;
}

.quickLinks h3 {
	margin: 0;
	padding-left: 20px;
	margin-bottom: 10px;
}

.childList h3 {
	margin: 0;
	padding-left: 20px;
	margin-bottom: 10px;
}

h4 {
	font-size: 1.7em;
	font-weight: 700;
	padding-left: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}

.navintro h4 {
	font-size: 18px;
	margin-top: 10px;
	font-weight: 300;
	margin-bottom: 10px;
}

.hpPanelRow h3 {
	margin: 5px 0 13px 7px;
}

h3.outdent {
	margin: 14px 10px 13px 10px;
	padding-top: 10px;
	border-top: 1px solid #b3b3b3;
}

.inner p {
	font-size: 105%;
}

.inner a {
	text-decoration: underline;
}

.inner ul {
	list-style: disc;
	margin-left: 40px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.inner h1 {
	margin: 10px 0 10px 0;
}

.detailheader p {
	padding: 10px 0 10px 0;
}

/* Header */

.boardTier {
	margin: 0 -15px !important;
	.boardProfile,
	.boardProfilePromoted {
		min-height: 220px;
		a p {
			clear: left;
			color: #000;
			font-weight: 700;
			font-size: 0.8em;
		}
		.img-container {
			background-color: #eee;
			width: 100%;
			height: 100%;
			max-width: 180px;
			min-height: 130px;
			display: block;
			padding-top: 66%;
			position: relative;
			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}

.searchcontainer,
.bannercontainer {
	padding: 13px 0;
	min-height: 120px;
}

.headsearch {
	padding: 0 0 0 6px;
}

.searchcontainer {
	background: #e9e9e9;
	width: 100%;
}

ul.searchlinks {
	list-style-type: none;
	margin-left: 15px;
	margin-bottom: 20px;
	width: 90%;
	border-bottom: 2px solid #fff;
	float: left;
}

ul.searchlinks li {
	float: left;
	padding: 0 0;
	font-size: 72%;
	color: #000;
}

ul.searchlinks li a {
	color: #000;
	font-size: 1.2em;
	padding: 0 5px;
	display: inline;
	border-bottom: none;
}

ul.searchlinks li::after {
	content: "|";
}

ul.searchlinks li:last-child::after {
	content: "";
}

.searchcontainer form {
	padding: 55px 0 0 20px;
}

.searchcontainer form input {
	height: 26px;
	float: left;
	width: 71%;
	margin: 0;
	border: 1px solid #79a6c3;
	background: #fff;
	box-shadow: none;
}

.searchcontainer form input.submitsearch {
	background: #bcd2e0;
	text-transform: uppercase;
	width: 20%;
	font-size: 80%;
}

.headbanner {
	padding: 0 0 0 0;
}

.bannercontainer {
	border-left: 2px solid #fff;
	background: #e9e9e9;
	text-align: center;
}

.bannercontainer img {
	height: auto;
	width: 100%;
	padding: 0 15px;
}

.healthAlert {
	background: #df0513;
}

.healthAlert .Alerttitle {
	letter-spacing: 1px;
	padding: 14px 17px;
	margin: 17px;
	float: left;
	background: #9d1006 url(/img/Alert.png) no-repeat;
	color: #fff;
	font-weight: 300;
	padding-left: 80px;
	font-size: 2em;
	width: 36%;
}

.healthAlert .Alerttext {
	float: left;
	border-top: 2px solid #9d1006;
	border-bottom: 2px solid #9d1006;
	color: #fff;
	font-size: 1.3em;
	font-weight: 300;
	margin-top: 20px;
	width: 51%;
	min-height: 70px;
	margin-bottom: 20px;
}

.healthAlert .Alerttext p {
	color: #fff;
}

.healthAlert .Alerttext a {
	color: #fff;
	text-decoratioN: underline;
}

.healthAlert .alerticon {
	background: #9d1006 url(/img/Alert.png) no-repeat;
	float: left;
	width: 79px;
	height: 70px;
	margin: 20px 17px 0;
}

.healthAlert.panel-border {
	padding-right: 0;
}

body .healthAlertLocation .Alerttitle {
	width: 14%;
}

body .healthAlertLocation .Alerttext {
	width: 74%;
}

.subnav {
	padding-left: 0;
	padding-right: 0;
}

.indent {
	padding-right: 16px;
	padding-left: 0;
}

.header {
	padding-right: 15px;
}

.select-style {
	width: 250px;
	overflow: hidden;
	margin-bottom: 5px;
	text-transform: uppercase;
	float: left;
	margin-left: 0;
	height: 26px;
	background: #e5f4fb url("/img/selectbg.png") no-repeat;
}

.select-style select {
	padding: 5px 8px;
	width: 270px;
	border: none;
	box-shadow: none;
	background: transparent;
	background-image: none;
	color: #000;
	font-size: 12px;
	text-transform: uppercase;
	overflow: hidden;
	-webkit-appearance: none;
}

.select-style select option {
	text-transform: uppercase;
}

select {
	text-transform: uppercase;
}

.select-style select:focus {
	outline: none;
}

.selectsearch {
	background: url(img/selectbutton.png) no-repeat;
	width: 23px;
	height: 24px;
	border: none;
	text-indent: -20009px;
	overflow: hidden;
	margin-top: 1px;
	margin-left: 6px;
}

.selectsearch:hover {
	background: url(img/selectbutton_hover.png) no-repeat;
}

.logo {
	float: right;
	width: 73%;
	margin: 26px 14px;
}

.navholder .logoholder {
	position: absolute;
	top: 0;
	right: 15px;
}

.navholder {
	padding-left: 7px;
	padding-right: 0;
}

.navigation {
	padding-right: 0;
	padding-left: 0;
	margin-top: 12px;
}

.navintro {
	width: 70%;
	float: left;
	border-bottom: 1px solid #206a99;
	font-size: 1.2em;
}

.navimage {
	float: right;
}

/* Mobile Nav*/

.toggleMenu {
	border-top: 1px solid #006f99;
	border-bottom: 1px solid #006f99;
	width: 100%;
	margin-top: 10px;
	text-align: right;
	display: none;
	background: #666;
	padding: 10px 15px;
	color: #fff;
}

.nav {
	list-style: none;
	*zoom: 1;
	background: #fff;
	width: 100%;
}

.nav:before,
.nav:after {
	content: " ";
	display: table;
}

.nav:after {
	clear: both;
}

.nav ul {
	list-style: none;
	width: 9em;
}

.nav a {
	padding: 10px 15px;
	color: #fff;
	text-indent: 5px;
	line-height: 25px;
}

.nav li.homenav a {
	color: #000;
	font-weight: bold;
}

.nav li.patientsnav a {
	background: #a8d8ef;
	color: #000;
	font-weight: bold;
}

.nav li.aboutnav a {
	background: #ffbd4d;
	color: #000;
	font-weight: bold;
}

.nav li.performancenav a {
	background: #2da867;
	color: #000;
	font-weight: bold;
}

.nav li.workingnav a {
	background: #e31b73;
	color: #000;
	font-weight: bold;
}

.nav li.healthnav a {
	background: #016f94;
	color: #000;
	font-weight: bold;
}

.nav li {
	position: relative;
}

.nav>li {
	float: left;
	border-top: 1px solid #104336;
}

.nav>li>.parent {
	background-image: url("/img/downArrow.png");
	background-repeat: no-repeat;
	background-position: right;
}

.nav>li>a {
	display: block;
	padding: 0;
}

.nav li ul {
	position: absolute;
	left: -9999px;
}

.nav>li.hover>ul {
	left: 0;
}

.nav li li.hover ul {
	left: 100%;
	top: 0;
}

.nav li li a {
	display: block;
	background: #ddd;
	position: relative;
	z-index: 100;
	border-top: 1px solid #175e4c;
}

.nav li li li a {
	background: #ccc;
	z-index: 200;
	border-top: 1px solid #1d7a62;
}

.outdentpanel {
	margin-left: -17px;
	padding: 0 8px;
}

@media screen and (max-width: 768px) {
	.homepageAccordion .accContent {
		padding-right: 10px;
	}
	.active {
		display: block;
	}
	.nav>li {
		float: none;
	}
	.nav>li>.parent {
		background-position: 95% 50%;
	}
	.nav li li .parent {
		background-image: url("/img/downArrow.png");
		background-repeat: no-repeat;
		background-position: 95% 50%;
	}
	.nav ul {
		display: block;
		width: 100%;
	}
	.nav>li.hover>ul,
	.nav li li.hover ul {
		position: static;
	}
}

.hospitalholder p.hTitle {
	font-weight: 700;
}

.hospitalsideholder .sidecontent h3.paneltitle span.openCloseHolder {
	display: none;
}

.breadcrumb {
	padding: 10px 0;
	margin-bottom: 0;
	list-style: none;
	margin-left: 21px;
	background-color: #e9e9e9;
	border-radius: 0;
}

.breadcrumb li a {
	color: #000;
}

.homepage .extend {
	border-bottom: 1px solid #9b9b9b;
	margin-bottom: 0;
	padding-right: 0;
	padding-top: 15px;
}

.extend {
	padding-right: 0;
	background: #e9e9e9;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 17px;
}

.gateway .extend {
	background: #e9e9e9;
	margin-bottom: 0;
	padding: 0 0 0 10px;
}

.wide {
	margin-right: 20px;
}

.gateway .carousel .wide {
	margin-left: 1px;
}

.fullWidthjumbo {
	padding-right: 20px;
}

a.topLevel {
	width: 16.666667%;
	float: left;
	margin-bottom: 10px;
	text-align: center;
	color: black;
	padding: 9px 0;
	border-right: 1px solid #e9e9e9;
	margin: 0;
	text-transform: capitalize;
}

li.home a {
	border-top: 5px solid #d9d9d9;
	background: #fff;
	border-left: 1px solid #d9d9d9;
}

li.home a:hover {
	border-top: 5px solid #d9d9d9;
	background: #ece9e9;
	border-left: 1px solid #d9d9d9;
}

li.patientTop a {
	border-top: 5px solid #206a9a;
}

li.patientTop a:hover {
	background: #a8d8ef;
}

li.patientTop .megaDropDown {
	background: #a8d8ef;
}

li.about a {
	border-top: 5px solid #e05800;
}

li.about a:hover {
	background: #ffc04d;
}

li.about .megaDropDown {
	background: #ffc04d;
}

li.yourHealth a {
	border-top: 5px solid #e51b72;
}

li.yourHealth a:hover {
	background: #f28db8;
}

li.yourHealth .megaDropDown {
	background: #f28db8;
}

li.workingWithUs a {
	border-top: 5px solid #006f94;
}

li.workingWithUs a:hover {
	background: #80b7c9;
}

li.workingWithUs .megaDropDown {
	background: #80b7c9;
}

li.OurPerformance a {
	border-top: 5px solid #37ae6d;
}

li.OurPerformance a:hover {
	background: #9bd6b6;
}

li.OurPerformance .megaDropDown {
	background: #9bd6b6;
}

label {
	font-size: 100%;
	line-height: 1.6em;
	font-weight: normal;
}

.homeslide {
	padding: 0 15px 15px 0;
}

.homepage .carousel {
	margin-left: 7px;
	@media @desktop-wide-min {
		width: 850px;
	}
}

.content_patientsandvisitors .carousel {
	margin-left: 0;
}

.gateway .carousel {
	margin-left: 12px;
}

.carousel {
	margin-left: 0;
}

.carousel-caption {
	display: none;
	left: 0;
	top: 0;
	padding-bottom: 0;
	padding: 2px 0 0 10px;
	text-align: left;
	color: #000;
	text-shadow: none;
	background: url(/img/captionbg.png) repeat;
	width: 200px;
	font-size: 14px;
}

div#myCarousel.carousel.slide div.carousel-inner div.item img {
	max-width: none;
}

.sidecontent {
	padding-right: 0;
	margin: 0;
	width: 100%;
	min-height: 340px;
	border: 2px solid #206a9b;
}

.paneltitle {
	background: #206a9b url(/img/FindNearest.png) no-repeat;
	background-position: right;
	margin: 0;
	padding: 10px 15px 10px 20px;
	font-size: 120%;
	letter-spacing: 0.5px;
	color: #fff;
	font-weight: 500;
}

.carousel-control.left,
.carousel-control.right {
	background-image: none;
	filter: none;
	background-repeat: repeat-x;
}

.carousel-control {
	width: 31px;
}

.glyphicon-chevron-right:before,
.glyphicon-chevron-left:before {
	content: "";
}

.glyphicon-chevron-left {
	background: url(/img/arrowleft.png) no-repeat;
	background-size: 100%;
}

.glyphicon-chevron-right {
	background: url(/img/arrowright.png) no-repeat;
	background-size: 100%;
}

div.sidecontent {
	background: #fff;
}

div.sidecontent ul {
	border-top: 1px solid #d6d6d6;
}

div.sidecontent ul li {
	list-style-type: none;
}

div.sidecontent ul li label {
	font-weight: normal;
	letter-spacing: 0;
	width: 100%;
	margin-bottom: 0;
	line-height: 1.6em;
}

.section1 .sidecontent {
	border: 2px solid #206a9a;
}

.section1 .sidecontent .paneltitle {
	background: #206a9a;
}

.section2 .sidecontent {
	border: 2px solid #e05800;
}

.section2 .sidecontent .paneltitle {
	background: #e05800;
}

.section3 .sidecontent {
	border: 2px solid #37ae6d;
}

.section3 .sidecontent .paneltitle {
	background: #37ae6d;
}

.section4 .sidecontent {
	border: 2px solid #006f94;
}

.section4 .sidecontent .paneltitle {
	background: #006f94;
}

.section5 .sidecontent {
	border: 2px solid #e51b72;
}

.section5 .sidecontent .paneltitle {
	background: #e51b72;
}

.sidecontent ul.sidenav {
	list-style-type: none;
	margin: 10px 20px;
}

.sidecontent ul.sidenav li {
	border-bottom: 1px solid #d6d6d6;
	float: left;
	width: 100%;
}

.sidecontent ul.sidenav li a {
	font-size: 90%;
	margin-bottom: 0;
	line-height: 1.6em;
	width: 100%;
	float: left;
}

.section1 .sidecontent ul.sidenav li a {
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: right;
}

.section2 .sidecontent ul.sidenav li a {
	background: url(/img/aboutarrow.png) no-repeat;
	background-position: right;
}

.section3 .sidecontent ul.sidenav li a {
	background: url(/img/performancearrow.png) no-repeat;
	background-position: right;
}

.section4 .sidecontent ul.sidenav li a {
	background: url(/img/healtharrow.png) no-repeat;
	background-position: right;
}

.section5 .sidecontent ul.sidenav li a {
	background: url(/img/workingarrow.png) no-repeat;
	background-position: right;
}

.postcodeHolder label {
	letter-spacing: -1px;
}

.carousel-inner>.item>img {
	//min-height: 340px;
	width: 100%;
}

.nearestsearchparas {
	padding: 0 20px 0 20px;
	font-size: 90%;
}

.nearestsearchparas ul {
	margin-top: 10px;
}

.nearestsearchparas li {
	border-bottom: 1px solid #d6d6d6;
	padding: 2px 0;
}

.postcodeHolder li label {
	margin-right: 11px;
	width: 100%;
}

.postcodeHolder input {
	float: right;
}

.nearestsearchparas input[type=radio] {
	float: right;
	text-align: right;
	background: #fff;
}

button {
	height: 26px;
	width: 85%;
	border: 1px solid #79a5c0;
	box-shadow: none;
	background: #bcd2e0;
	text-transform: uppercase;
	font-size: 80%;
	margin: 20px auto 10px auto;
}

.shortbutton {
	height: 26px;
	width: 40%;
	margin: 17px 0 17px 20px;
}

.gobutton {
	height: 26px;
	width: 40%;
	margin: 0 0 17px 0;
	background: #206a9b;
	color: #fff;
	border: none;
}

.hospitals {
	padding-right: 0;
	padding-left: 15px;
}

.homepage .hospitals {
	padding-right: 0;
	padding-left: 8px;
}

.hospitals ul li a {
	color: #000;
}

.content-container {
	padding: 0;
	font-size: 126%;
}

.mobilelogo {
	width: 110px;
	margin-top: 10px;
	height: auto;
}

.news .content-container,
.twitter .content-container {
	padding: 10px;
	font-size: 126%;
}

.panel-border {
	padding-left: 0;
	padding-right: 15px;
}

.panel-holder {
	padding-right: 0;
	overflow: hidden;
}

.sidenav-holder {
	padding-right: 0;
	height: auto;
}

.image-holder {
	padding-right: 0;
	height: 357px;
	padding-left: 18px;
}

.panel {
	box-shadow: none;
	border-radius: 0;
	padding-left: 0;
	margin-left: 0;
	border: none;
	overflow: hidden;
}

.sidenav {
	box-shadow: none;
	border-radius: 0;
	padding-left: 0;
	margin-left: 0;
	border: none;
	height: auto;
	overflow: hidden;
	min-height: 340px;
}

.imagepanel {
	background: none;
	height: auto;
}

.border {
	border-top: 2px solid #cdcdcb;
}

ul.quicklink li.top {
	background: none;
	border-top: none;
	line-height: 2em;
	font-size: 0.9em;
}

.imagediv {
	height: 345px;
	display: block;
	background-repeat: no-repeat;
	background-position: top center;
	background-size: cover;
}

.shortPanels .imagediv {
	height: 190px;
}

div.hospitals ul {
	display: block;
}

.foreground-overlay-bottom-stripoverlay {
	background: red;
}

.panel-section-top-strip {
	height: 5px;
	display: block;
}

.top-image img {
	width: 100%;
}

.background-image img {
	width: 100%;
}

.news {
	border: 2px solid #ffc04d;
	font-size: 86%;
	letter-spacing: 0;
}

.twitter {
	border: 2px solid #07a7e3;
	font-size: 86%;
	letter-spacing: 0;
}

.pateint {
	border: 2px solid #a8d8ef;
	font-size: 86%;
	letter-spacing: 0;
}

.aboutUs {
	border: 2px solid #ffbd4d;
	letter-spacing: 0;
}

.fullimage img {
	width: 100%;
	max-height: 340px;
	height: 340px;
}

.content-container img {
	width: 100%;
	height: auto;
	max-height: 340px;
}

ul.newsLatest img {
	float: right;
	margin: 0 0 10px 10px;
	padding-bottom: 5px;
	width: 90px;
	height: auto;
}

ul.newsLatest li {
	border-bottom: 1px solid #ccc;
	width: 100%;
	min-height: 84px;
	float: left;
}

ul.newsLatest li .newsTitle {
	font-size: 12px;
	line-height: 16px;
	font-family: 'arial';
	margin-top: -5px;
	margin-bottom: 2px;
}

ul.newsLatest li a.ReadMore {
	font-size: 11px;
	margin-top: 5px;
	font-family: arial;
	color: #e05800;
	text-decoration: underline;
}

// ul.twitterlist li {
// 	border-bottom: 1px solid #ccc;
// 	width: 100%;
// 	min-height: 90px;
// 	list-style-type: none;
// 	margin-bottom: 10px;
// }

// ul.twitterlist li .newsTitle {
// 	font-size: 12px;
// 	line-height: 16px;
// 	font-family: arial;
// 	margin-top: -5px;
// 	margin-bottom: 2px;
// }

// ul.twitterlist li a.newsDate {
// 	font-size: 11px;
// 	font-family: arial;
// 	position: relative;
// 	display: inline;
// 	top: -8px;
// }

// ul.twitterlist li a.ReadMore {
// 	font-size: 11px;
// 	margin-top: 5px;
// 	font-family: arial;
// 	color: #07a7e3;
// 	text-decoration: underline;
// }

h2.maintitle {
	font-size: 2.2em;
	font-weight: 300;
}

.main {
	padding-left: 20px;
	padding-right: 0;
}

.gateway .main {
	padding-left: 0;
	padding-right: 0;
	margin-left: -14px;
}

.mobilecontainer {
	padding-right: 0;
	width: 100%;
}

.openaccord,
.closeaccord {
	position: relative;
	font-size: 0.8em;
	float: right;
	margin-top: -35px;
	margin-right: 20px;
	padding-right: 35px;
	text-transform: uppercase;
	text-decoration: underline;
	z-index: -1;
}

.openaccord {
	background: url(/img/openarrow.png) no-repeat;
	background-position: right;
}

.closeaccord {
	background: url(/img/closearrow.png) no-repeat;
	background-position: right;
}

.open,
.openclose {
	display: none;
	float: right;
}

.accordTitle {
	width: 100%;
	float: left;
	line-height: 2em;
}

.transparent {
	/* Required for IE 5, 6, 7 */
	/* ...or something to trigger hasLayout, like zoom: 1; */
	width: 100%;
	/* Theoretically for IE 8 & 9 (more valid) */
	/* ...but not required as filter works too */
	/* should come BEFORE filter */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	/* This works in IE 8 & 9 too */
	/* ... but also 5, 6, 7 */
	filter: alpha(opacity=50);
	/* Modern!
	/* Firefox 0.9+, Safari 2?, Chrome any?
	/* Opera 9+, IE 9+ */
	opacity: 0.5;
}

.cf:before,
.cf:after {
	content: " ";
	display: table;
}

.cf:after {
	clear: both;
}

.cf {
	*zoom: 1;
}

#menu h2 {
	margin-top: 5px;
}

/*
    HOSPITALS    
*/

.dt_holder {
	float: left;
	width: 100%;
	margin-bottom: 20px;
}

.dt_holder dt {
	float: left;
	cursor: pointer;
	line-height: 2em;
	padding-left: 1.25%;
	padding-right: 1.25%;
	color: #206a9a;
}

.dt_holder dt:hover {
	color: #000;
}

.dl_holder {
	clear: both;
	margin-top: 10px;
}

dl dl.wards dt,
dl dl.services dt {
	width: 100%;
	padding: 10px 0 10px 0;
	font-size: 15px;
}

dl dl.wards dt.active,
dl dl.services dt.active {
	background: #e5f4fb;
}

dl dl.wards dd,
dl dl.services dd,
dl.hospital dd {
	width: 100%;
	padding: 0 10px 5px 10px;
	ul {
		margin-bottom: 20px;
		list-style-type: none;
		margin-left: 20px;
		li {
			padding-left: 25px;
			position: relative;
			&:before {
				position: absolute;
				left: 0;
				top: 0px;
				display: inline-block;
				content: "\2022";
				color: #0e7ac4;
				font-size: 22px;
				line-height: 1;
			}
		}
	}
}

dl dl.wards dd.active,
dl dl.services dd.active {
	background: #e5f4fb;
	float: left;
}

.newsresult {
	width: 100%;
	border-bottom: 1px solid #d8d8d8;
}

dl.months dt {
	width: 100%;
	padding: 5px 10px 5px 0;
	font-size: 15px;
	text-transform: uppercase;
}

dl.months dt.active {
	background: #ffbe4d;
	border-bottom: 1px solid #e05800;
	border-top: 1px solid #e05800;
}

dl.months dd {
	width: 100%;
	padding: 10px 10px 10px 0;
	font-size: 0.9em;
}

dl.months dd.active {
	float: left;
}

dl dl.wards dt {
	background: #ffffff url(/img/hospitalarrow.png) no-repeat;
	background-position: 99% 12px;
}

dl dl.wards dt.active {
	background: #e5f4fb url(/img/hospitalarrowdown.png) no-repeat;
	background-position: 99% 12px;
}

dl dl.wards dt strong,
dl dl.wards dt.active strong {
	padding-left: 10px;
	width: 70%;
	float: left;
}

dl dl.services dt {
	background: #ffffff url(/img/hospitalarrow.png) no-repeat;
	background-position: 99% 12px;
}

dl dl.services dt.active {
	background: #e5f4fb url(/img/hospitalarrowdown.png) no-repeat;
	background-position: 99% 12px;
}

dl dl.services dt strong,
dl dl.services dt.active strong {
	padding-left: 10px;
}

dl.months dt {
	background: #ffffff url(/img/aboutaarrow.png) no-repeat;
	background-position: 99% 12px;
	float: left;
	border-bottom: 1px solid #ffc04d;
	cursor: pointer;
}

dl.months dt.active {
	background: #ffc04d url(/img/aboutaarrowdown.png) no-repeat;
	background-position: 99% 12px;
}

dl.months dd a {
	padding-left: 20px 0 20px 0;
	color: #000;
	font-weight: 700;
}

dl.months dd a:hover {
	color: #e05800;
}

.locationDetails {
	border-bottom: 2px solid #ccc;
	margin-bottom: 20px;
	font-weight: 300;
}

.dt_holder.row {
	border-bottom: 1px solid #206a9a;
}

dl.hospital dt.tabs {
	border: 0;
	background: #e9e9e9;
	padding: 5px 12px;
	border-left: 1px solid #999;
	border-top: 1px solid #999;
	border-right: 1px solid #999;
	color: #999;
}

dl.hospital dt.tabs.active {
	background: #fff;
	border: none;
	border-bottom: 0;
	color: #206a9b;
	border-left: 1px solid #206a9a;
	border-top: 1px solid #206a9a;
	border-right: 1px solid #206a9a;
}

dl.hospital dt {
	float: left;
	margin-right: 2px;
	font-weight: normal;
	padding: 0;
	color: black;
	cursor: pointer;
	border-bottom: 1px solid #d3ebf7;
	background: #fff;
}

dl.hospital dt .wardTelephone {
	display: block;
	float: right;
	width: 30%;
	color: #206a9b;
	padding-right: 20px;
}

dt#Head {
	color: #206a9b;
	text-transform: uppercase;
	text-align: left;
	background: none;
	padding-left: 10px;
}

.cookieHolder {
	width: 100%;
	height: auto;
	display: none;
	padding: 15px;
	border-bottom: 1px solid #888;
	box-shadow: 0 -12px 10px 10px #000000;
	position: fixed;
	z-index: 9999999;
	background: #eeeeee;
	/* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjY2NjY2MiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	background: -moz-linear-gradient(top, #eeeeee 0, #cccccc 100%);
	/* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #eeeeee), color-stop(100%, #cccccc));
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #eeeeee 0, #cccccc 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #eeeeee 0, #cccccc 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #eeeeee 0, #cccccc 100%);
	/* IE10+ */
	background: linear-gradient(to bottom, #eeeeee 0, #cccccc 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#cccccc', GradientType=0);
	/* IE6-8 */
}

.cookieHolder a {
	text-decoration: none;
	background: #90c944;
	color: white;
	font-size: 14px;
	line-height: 13px;
	margin-left: 20px;
	margin-right: 20px;
	padding: 6px 26px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
}

.cookieicon {
	float: right;
	margin-top: -18px;
	margin-right: -15px;
}

figure:before {
	content: "?";
	position: absolute;
	background: rgba(255, 255, 255, 0.75);
	color: black;
	width: 16px;
	height: 16px;
	bottom: 6px;
	left: 6px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	text-align: center;
	font-size: 10px;
	line-height: 18px;
	/* Only Fx 4 supporting transitions on psuedo elements so far... */
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	transition: all 0.6s ease;
	opacity: 0.75;
}

figure:hover:before {
	opacity: 0;
}

figure {
	display: block;
	position: relative;
	overflow: hidden;
	width: 180px;
	height: 154px;
	float: left;
	margin: 2px;
}

figcaption {
	position: absolute;
	background: #ffc04d;
	color: black;
	padding: 10px 20px;
	opacity: 0;
	bottom: -30%;
	left: 0;
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
	transition: all 0.6s ease;
}

figure:hover figcaption {
	opacity: 0.92;
	bottom: 0;
}

dl.hospital dd,
dl.months dd {
	/*display: none;*/
	margin-bottom: 10px;
}

.paddingLR {
	padding: 0 10px;
}

.paddingTB {
	padding: 10px 0;
}

.layout {
	padding: 10px;
}

.footer {
	margin-top: 20px;
	background: #e9e9e9;
	float: left;
	width: 100%;
}

.footer ul {
	margin-top: 20px;
	list-style-type: none;
}

.footer li {
	font-size: 13px;
}

.footer li a {
	border-bottom: 1px solid #fff;
	line-height: 2em;
	width: 100%;
	display: block;
	color: #000;
	-ms-word-wrap: break-word;
	word-wrap: break-word;
}

.footer li .footertitle {
	text-transform: uppercase;
	color: #0a2c73;
	border-bottom: none;
}

.logocolumn {
	border-right: 2px solid #fff;
	padding: 20px;
	min-height: 250px;
}

div.trail {
	margin-bottom: 10px;
	font-size: 90%;
}

.topSection {
	padding-left: 0;
	padding-right: 15px;
	position: relative;
}

.topSection ul {
	list-style-type: none;
}

.topSection ul li>div.megaDropDown {
	visibility: hidden;
	opacity: 0;
	/*
            -webkit-transition: visibility 0s linear 0.5s,opacity 0.5s linear;
            -moz-transition: visibility 0s linear 0.5s,opacity 0.5s linear;
            -o-transition: visibility 0s linear 0.5s,opacity 0.5s linear;
            transition: visibility 0s linear 0.5s,opacity 0.5s linear;
            */
}

.topSection ul li:hover>div.megaDropDown {
	visibility: visible;
	opacity: 1;
	/*
            -moz-transition-delay: 0s;
            -o-transition-delay: 0s;
            -webkit-transition-delay: 0s;
            transition-delay: 0s;
            */
}

.topSection .navintro {
	width: 70%;
	float: left;
	border-bottom: 1px solid #206a99;
}

.topSection .navimage {
	float: right;
	width: 28%;
	margin-top: 55px;
}

.topSection .navitem {
	float: left;
	min-height: 65px;
	width: 33.5%;
	margin-top: 10px;
	margin-right: 17px;
	border-bottom: 1px solid green;
	letter-spacing: 0;
	position: relative;
	padding-top: 20px;
}

.topSection .navitem a {
	font-weight: 700;
	font-size: 90%;
	width: 100%;
	float: left;
	color: #000;
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: top right;
	border-top: none;
}

.topSection .fulldiv {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: top;
}

.topSection .patientTop .navintro {
	width: 70%;
	float: left;
	border-bottom: 1px solid #206a9a;
}

.topSection .patientTop .navitem {
	border-bottom: 1px solid #206a9a;
}

.topSection .patientTop .navitem a {
	font-weight: 700;
	font-size: 90%;
	width: 100%;
	float: left;
	color: #000;
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: top right;
}

.topSection .about .navintro {
	width: 70%;
	float: left;
	border-bottom: 1px solid #e05800;
}

.topSection .about .navitem {
	border-bottom: 1px solid #e05800;
}

.topSection .about .navitem a {
	font-weight: 700;
	font-size: 90%;
	width: 100%;
	float: left;
	color: #000;
	background: url(/img/aboutarrow.png) no-repeat;
	background-position: top right;
}

.topSection .OurPerformance .navintro {
	width: 70%;
	float: left;
	border-bottom: 1px solid #37ae6d;
}

.topSection .OurPerformance .navitem {
	border-bottom: 1px solid #37ae6d;
}

.topSection .OurPerformance .navitem a {
	font-weight: 700;
	font-size: 90%;
	width: 100%;
	float: left;
	color: #000;
	background: url(/img/performancearrow.png) no-repeat;
	background-position: top right;
}

.topSection .workingWithUs .navintro {
	width: 70%;
	float: left;
	border-bottom: 1px solid #006f94;
}

.topSection .workingWithUs .navitem {
	border-bottom: 1px solid #006f94;
}

.topSection .workingWithUs .navitem a {
	font-weight: 700;
	font-size: 90%;
	width: 100%;
	float: left;
	color: #000;
	background: url(/img/healtharrow.png) no-repeat;
	background-position: top right;
}

.topSection .yourHealth .navintro {
	width: 70%;
	float: left;
	border-bottom: 1px solid #e51b72;
}

.topSection .yourHealth .navitem {
	border-bottom: 1px solid #e51b72;
}

.topSection .yourHealth .navitem a {
	font-weight: 700;
	font-size: 90%;
	width: 100%;
	float: left;
	color: #000;
	background: url(/img/workingarrow.png) no-repeat;
	background-position: top right;
}

.topSection .navitem p {
	width: 80%;
	color: #000;
	line-height: 1.15em;
}

.topSection .megaDropDown {
	width: 98.5%;
	position: absolute;
	top: 45px;
	left: -2px;
	padding: 0 0 20px 0;
	z-index: 100000;
	color: black;
	/*display: none;*/
}

.topSection .megaDropDown img {
	margin-bottom: 10px;
	width: 100%;
	height: auto;
}

.topSection .megaDropDown ul li a {
	width: 100%;
	text-align: left;
}

.topSection .megaDropDown .layout {
	padding: 10px 17px;
}

.topSection .megaNavLinks a {
	font-size: 20px;
	color: white;
	width: 50%;
	display: block;
}

.topSection a:hover .megaDropDown {
	display: block;
}

.hospitalholder {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 20px;
}

.hospitalsideholder {
	padding-left: 0;
	margin-bottom: 10px;
}

.hospitalsideholder strong {
	letter-spacing: 0;
}

.hospitals strong {
	letter-spacing: 0;
}

.searchhosp {
	padding-left: 10px;
}

.lowerside {
	padding-left: 0;
}

.lowerside .panel-holder {
	padding-left: 0;
}

UL.locationTypes {
	list-style-type: none;
	margin-left: 0;
}

div.hospitals p {
	margin-bottom: 5px;
	float: left;
}

div.hospitals ul {
	float: right;
	width: 100%;
	margin: 0;
	padding: 0;
}

div.hospitals ul li {
	color: #000;
	/*width: 23%;*/
	width: 31.1%;
	float: left;
	list-style-type: none;
	font-size: 0.9em;
	padding: 0;
	margin: 0;
	line-height: 1em;
	border-bottom: 1px solid #206a9b;
	background: url(/img/hospitalarrow.png) no-repeat;
	background-position: right center;
	margin-right: 22px;
}

div.hospitals ul li a {
	padding: 5px 0;
	float: left;
}

div.hospitals img {
	float: left;
	width: 15%;
}

.padding {
	padding: 14px;
}

.homepageFancyPanels.noPadding {
	padding: 0;
}

a {
	color: black;
}

a:hover,
a.patient:hover {
	text-decoration: none;
	color: #000;
}

#menus {
	border: 1px solid black;
}

#menus ul.nav li {
	list-style-type: none;
}

#menus ul.nav li a {
	text-decoration: none;
	margin-bottom: 3px;
	display: block;
}

#menus ul.nav li ul {
	margin-left: 15px;
}

ul.menuTrail {
	padding-left: 20px;
}

ul.menuTrail li {
	float: left;
	margin-right: 5px;
	list-style-type: none;
	font-size: 0.9em;
}

ul.menuTrail li:last-child {
	font-weight: bold;
}

li.rootNode a {
	color: green;
}

/* OVERRIDE BOOTSTRAP */

.findMyNearestPartial .currentLocation {
	text-align: center;
}

.findMyNearestPartial .usingCurrentLocation {
	display: none;
	font-size: 0.9em;
	color: #000;
	padding: 10px 20px;
	font-weight: 300;
}

.inner ul.newsResults {
	list-style: none;
	margin-left: 0;
}

.inner ul.newsResults li {
	width: 48%;
	float: left;
}

.inner ul.newsResults li:nth-child(odd) {
	margin-left: 4%;
}

#search-results .panel-body {
	padding: 0;
	padding: 10px;
}

#search-results .locationResults .panel .panel-body {
	background: #f3f3f3;
	padding: 10px;
}

#search-results .panel-heading {
	padding: 0;
}

#search-results .panel-heading h3 {
	padding: 10px;
}

#serchNews input {
	float: right;
}

#serchNews {
	padding-bottom: 20px;
}

.findNearestResultsList .panel .panelbody {
	min-height: 100px;
}

.newsResultsList .panel-body {
	min-height: 170px;
}

.newsResultsList li {
	width: 48%;
	float: left;
}

.newsResultsList li:nth-child(odd) {
	margin-right: 4%;
	margin-left: 4%;
}

.docLibList .findNearestResults li {
	width: 48%;
	float: left;
}

.docLibList .findNearestResults li:nth-child(odd) {
	margin-right: 4%;
}

.findMyNearestResultsPartial #location-map {
	width: 100%;
	height: 200px;
}

.findMyNearestResultsPartial .findNearestResults {
	list-style: none;
	margin-left: 0;
}

.findMyNearestResultsPartial .findNearestResults li {
	width: 48%;
	float: left;
}

.findMyNearestResultsPartial .findNearestResults li:nth-child(odd) {
	margin-right: 4%;
}

.findMyNearestResultsPartial .paginationTopHolder {
	margin-left: 0;
}

.findMyNearestResultsPartial .paginationTopHolder .simple-pagination-showing-x-of-x {
	text-align: right;
}

.findMyNearestResultsPartial .paginationBottomHolder {
	width: 100%;
}

.findMyNearestResultsPartial .paginationBottomHolder .simple-pagination-page-numbers {
	text-align: center;
}

.panel-locations .searchTerm {
	margin-bottom: 5px;
}

.panel-locations .searchTerm label {
	width: 150px;
}

.panel-locations #keywords {
	width: 400px;
}

.panel-locations select {
	margin-bottom: 5px;
}

.panel-locations ul.locations {
	list-style: none;
	margin-bottom: 5px;
	clear: both;
}

.panel-locations ul.locations li {
	width: 50%;
	float: left;
}

.locationSearchResults ul.locations {
	list-style: none;
	/*margin-left: 0;*/
}

.locationSearchResults .paginationBottomHolder {
	width: 100%;
}

.locationSearchResults ul.locations li {
	float: left;
	padding: 0.1em;
	width: 32.333%;
	margin-right: 1%;
	margin-top: 10px;
	margin-bottom: 0;
}

@media only screen and (max-width: 940px) {
	.locationSearchResults ul.locations li {
		width: 49%;
	}
}

@media only screen and (max-width: 479px) {
	.locationSearchResults ul.locations li {
		width: 100%;
	}
}

.locationSearchResults .panel {
	height: auto;
	margin-bottom: 0;
}

.locationSearchResults .panel-heading {
	height: 4.1em;
}

@media only screen and (max-width: 479px) {
	.locationSearchResults .panel-heading {
		height: auto;
	}
}

@media only screen and (max-width: 479px) {
	ul.locationTypes li {
		width: 100%;
	}
}

ul.locations .panel-primary>.panel-heading {
	color: #206a9a;
	background: #f3f3f3;
	border-color: #428bca;
}

ul.locations .panel-primary>.panel-heading .panel-title {
	background: transparent;
	padding: 0;
	min-height: 35px;
}

.simple-pagination-first {
	display: none;
}

.simple-pagination-last {
	display: none;
}

#search-results h3 {
	margin: 0;
	padding-left: 0;
	margin-bottom: 10px;
	width: 100%;
	float: left;
}

#search-results h3.panel-title {
	float: none;
}

.locationTypes li {
	float: left;
	width: 49%;
}

#search-results h3.panel-title a {
	text-decoration: none;
}

.searchResults {
	margin-top: 15px;
}

.searchResults a.searchLink {
	font-size: 15px;
	font-weight: bold;
}

.searchResults a.searchLink.news {
	color: red;
}

.searchResults a.searchLink.content {
	color: black;
}

#search-results ul {
	list-style-type: none;
	margin-left: 0;
	width: 100%;
	float: left;
}

ul.newsLatest,
ul.panelLinks {
	list-style: none;
}

ul.newsLatest li,
ul.panelLinks li {
	margin-bottom: 8px;
}

ul.newsLatest li a,
ul.panelLinks li a {
	text-transform: capitalize;
}

.hpPanelRow {
	padding-left: 0;
	padding-right: 0;
}

.panel-group .panel-holder {
	height: 340px;
	overflow: hidden;
}

.panel-group .panel-holder.customPanel .panel-title {
	height: 30px;
	padding-left: 10px;
}

.panel-group .panel-holder.customPanel .content-container {
	padding: 12px;
}

.panel-group .panel-holder.customPanel .panel-short.twitter {
	border: 3px solid #07a7e3;
}

.panel-group .panel-holder.customPanel .panel-short.twitter .panel-title {
	background: #07a7e3;
	color: white;
}

.panel-group .panel-holder.customPanel .panel-short.news {
	border: 3px solid #ffc04d;
}

.panel-group .panel-holder.customPanel .panel-short.news .panel-title {
	background: #ffc04d;
	color: white;
}

.panel-group .panel-holder.customPanel .panel-short.navigation .panel-title {
	background: #ffc04d;
	color: white;
}

.panel-group .panel-holder .panel-content-container {
	position: relative;
	height: 334px;
}

.panel-group .panel-holder .panel-content-container .panel-section-top-strip {
	height: 3px;
	width: 100%;
}

.panel-group .panel-holder .panel-content-container .background-image {
	height: 337px;
	/* height of panel - top strip */
}

.panel-group .panel-holder .panel-content-container .foreground-overlay-bottom-strip {
	position: absolute;
	width: 100%;
	bottom: 0;
	min-height: 30px;
	opacity: 0.7;
	filter: alpha(opacity=70);
	/* For IE8 and earlier */
	font-weight: bold;
}

ul.searchResults li {
	border-bottom: 1px solid grey;
	padding: 5px;
}

ul.searchResults li a {
	font-size: 15px;
}

ul.searchResults li a:hover {
	color: red;
}

.paginationTopHolder .row .col-md-12,
.paginationBottomHolder .row .col-md-12 {
	padding-left: 0;
}

.paginationTopHolder {
	background: #fff;
	padding-left: 0;
	width: 100%;
	border-top: 1px solid #8ab3cf;
}

.paginationBottomHolder {
	background: #e5f4fb;
	padding-left: 0;
	border-bottom: 1px solid #8ab3cf;
	border-top: 1px solid #8ab3cf;
}

.paginationBottomHolder p {
	text-transform: uppercase;
	color: #1e71a7;
	padding: 0;
	width: auto;
	float: left;
	margin: 0;
}

.content1_aboutus .paginationBottomHolder {
	background: #ffc04d;
	padding-left: 0;
	border-bottom: 1px solid #e05800;
	border-top: 1px solid #e05800;
}

.content1_aboutus .paginationBottomHolder p {
	text-transform: uppercase;
	color: #e05800;
	padding: 0;
	width: auto;
	float: left;
	margin: 0;
}

.content1_aboutus .simple-pagination-page-numbers a {
	color: #e05800;
}

.simple-pagination-next,
.simple-pagination-previous {
	display: none;
}

a[class*="simple-pagination-navigation-disabled"] {
	text-decoration: none;
}

.simple-pagination-page-numbers a {
	color: #1e71a7;
}

.simple-pagination-page-numbers.col-md-4 {
	padding-left: 0;
}

.sidecolumn {
	padding-right: 0;
}

.sidecolumn div {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
}

.sidecolumn .fullimage img {
	height: auto;
}

.sidecolumn .findMyNearestPartial {
	padding: 0 20px;
	margin-bottom: 10px;
}

.row {
	margin-right: 0;
	margin-left: 0;
}

ul.findNearestResults {
	margin-bottom: 15px;
	float: left;
	margin-left: 0;
	list-style-type: none;
}

.alert {
	margin-bottom: 0;
}

.alert-info {
	background: #fff;
	color: #000;
	border-radius: 0;
	border: none;
	border-top: 1px solid #91b7cd;
	padding: 0;
}

.content1_aboutus .alert-info {
	background: #fff;
	color: #000;
	border-radius: 0;
	border: none;
	border-top: 1px solid #e05800;
	padding: 0;
}

.alert-info .row .col-md-6 {
	padding: 0;
}

.simple-pagination-showing-x-of-x.col-md-6 {
	float: none;
	padding: 0;
}

.searchType.col-md-6 {
	padding: 0;
}

.findMyNearestResultsPartial .paginationTopHolder .simple-pagination-showing-x-of-x {
	text-align: left;
	font-weight: bold;
	font-size: 95%;
	padding: 5px 0;
}

.content1_aboutus .simple-pagination-showing-x-of-x {
	text-align: left;
	font-weight: bold;
	font-size: 95%;
	padding: 5px 0;
}

.content1_aboutus div.moreresults p {
	color: #e05800;
}

.pagination {
	margin: 0;
	width: 100%;
}

.panel-body {
	background: #f3f3f3;
}

.findNearestResultsList.evenRow .panel-body {
	background: #fff;
}

.findNearestResultsList.evenRow .panel .panel-title {
	background: #fff;
}

.findNearestResultsList.evenRow .panel .panel-heading {
	background: #fff;
}

.newsResultsList.evenRow .panel-body {
	background: #fff;
}

.newsResultsList.evenRow .panel .panel-title {
	background: #fff;
}

.newsResultsList.evenRow .panel .panel-heading {
	background: #fff;
}

.pagination.paginationBottomHolder.col-md-12 {
	padding-left: 0;
	padding-right: 0;
}

.newsResultsList,
.findNearestResultsList .panel {
	margin-bottom: 0;
}

.findNearestResultsList .panel .panel-heading {
	background: #f3f3f3;
	padding: 0;
	border-bottom: 1px solid #bedff2;
}

.findNearestResultsList .panel .panel-title {
	background: #f3f3f3;
	border-top: 1px solid #bedff2;
	color: #206a9a;
}

.findNearestResultsList .panel .panel-title a {
	color: #000;
	font-weight: 300;
	font-weight: bold;
	font-size: 0.9em;
}

.newsResultsList .panel .panel-heading {
	background: #f3f3f3;
	padding: 0;
	border-bottom: 1px solid #e05800;
}

.newsResultsList .panel .panel-title {
	background: #f3f3f3;
	border-top: 1px solid #e05800;
	color: #e05800;
}

.newsResultsList .panel .panel-title a {
	color: #000;
	font-weight: 300;
	font-weight: bold;
	font-size: 0.9em;
}

.content1_aboutus .panel-heading .panel-title {
	color: #000;
	font-weight: 300;
	font-weight: bold;
	border-top: 1px solid #e05800;
	font-size: 0.9em;
}

.content1_aboutus .findNearestResultsList .panel .panel-heading {
	background: #f3f3f3;
	padding: 0;
	border-bottom: 1px solid #e05800;
}

div.moreresults {
	background: #e8e8e8;
	width: 100%;
	float: left;
	padding-top: 5px;
	margin-bottom: 15px;
	border-bottom: 1px solid #206a9a;
}

div.moreresults p {
	text-transform: uppercase;
	color: #206a9a;
	width: 170px;
	float: left;
	font-size: 95%;
	margin: 0 0 5px 0;
}

.content1_aboutus div.moreresults {
	background: #e8e8e8;
	width: 100%;
	float: left;
	padding-top: 5px;
	margin-bottom: 15px;
	border-bottom: 1px solid #e05800;
}

.container {
	width: 100%;
	max-width: 1170px;
	padding-right: 0;
}

@media (max-width: 1173px) {
	div.hospitals ul li {
		width: 31.1%;
	}
	div.hospitals ul.toplines li:last-child {
		display: none;
	}
	.healthAlert .Alerttext {
		width: 50%;
	}
}

@media (max-width: 1100px) {
	div.hospitals ul li {
		margin-right: 14px;
		width: 31%;
	}
}

@media (max-width: 1085px) {
	.postcodeHolder input {
		width: 140px;
	}
	.half-image-half-text span.panelicon .fa {
		position: absolute;
		top: 22%;
		left: 13%;
	}
	.half-image-half-text span.panelicon {
		display: block;
		width: 100%;
		height: 150px;
	}
}

@media (max-width: 1060px) {
	div.hospitals ul li {
		margin-right: 10px;
		width: 31.5%;
	}
}

@media (max-width: 1030px) {
	.postcodeHolder input {
		width: 120px;
	}
	div.hospitals ul li {
		margin-right: 10px;
		width: 31.5%;
		font-size: 14px;
	}
}

@media (max-width: 991px) {
	.searchcontainer form {
		float: right;
		right: 0;
		margin-top: -60px;
		width: 50%;
		padding: 0;
	}
	.headbanner {
		float: none;
		text-align: center;
		display: block;
		height: 110px;
		margin-top: 50px;
	}
	.searchcontainer {
		height: 50px;
	}
	.searchcontainer,
	.bannercontainer {
		min-height: 50px;
		margin-top: 0;
	}
	.bannercontainer {
		background: transparent;
		top: 0;
		margin-top: 0;
		margin: 0 auto;
		width: auto;
		text-align: center;
	}
	h1 {
		font-size: 2.4em;
		margin: 25px 15px;
	}
	.logo {
		margin: 10px;
	}
	ul.searchlinks {
		padding-bottom: 11px;
		width: 96%;
	}
	.logoholder {
		margin-top: 0;
		width: 20%;
	}
	div.hospitals ul {
		width: 100%;
	}
	.hospitalholder {
		padding-left: 0;
	}
	.topSection .patientTop .navintro {
		width: 70%;
	}
}

@media (max-width: 940px) {
	.topSection .navitem {
		width: 32%;
	}
	.footer .headbanner {
		display: none;
	}
	.cookieHolder {
		width: 250px;
		padding: 10px 0 0 10px;
		position: fixed;
		right: 10px;
		bottom: 10px;
		box-shadow: 0 4px 40px 1px #666;
	}
	.cookieHolder a {
		padding: 10px;
		margin: 10px 0 5px 0;
		clear: both;
		float: left;
	}
	.cookieicon {
		margin-right: 0;
		margin-top: 0;
	}
	.half-image-half-text span.panelicon .fa {
		position: absolute;
		top: 18%;
		left: 13%;
	}
	.half-image-half-text span.panelicon {
		display: block;
		width: 100%;
		height: 130px;
	}
}

@media (max-width: 890px) {
	a.topLevel {
		font-size: 90%;
	}
	.healthAlert .Alerttext {
		width: 45%;
		font-size: 1em;
		min-height: 60px;
	}
	.healthAlert .Alerttitle {
		font-size: 1.2em;
		height: 60px;
	}
	.healthAlert .alerticon {
		height: 60px;
	}
	body .healthAlertLocation .Alerttitle {
		display: none;
	}
	body .healthAlertLocation .Alerttext {
		width: 90%;
		margin-left: 5%;
		margin-top: 15px;
	}
}

@media (max-width: 800px) {
	.half-image-half-text span.panelicon .fa {
		position: absolute;
		top: 10%;
		left: 13%;
	}
	.half-image-half-text span.panelicon {
		display: block;
		width: 100%;
		height: 100px;
	}
	.healthAlert .Alerttext {
		width: 40%;
		font-size: 1em;
		min-height: 60px;
	}
	.healthAlert .Alerttitle {
		font-size: 1.2em;
		height: 60px;
	}
	.healthAlert .alerticon {
		height: 60px;
	}
}

@media (max-width: 768px) {
	.col-xs-1,
	.col-sm-1,
	.col-md-1,
	.col-lg-1,
	.col-xs-2,
	.col-sm-2,
	.col-md-2,
	.col-lg-2,
	.col-xs-3,
	.col-sm-3,
	.col-md-3,
	.col-lg-3,
	.col-xs-4,
	.col-sm-4,
	.col-md-4,
	.col-lg-4,
	.col-xs-5,
	.col-sm-5,
	.col-md-5,
	.col-lg-5,
	.col-xs-6,
	.col-sm-6,
	.col-md-6,
	.col-lg-6,
	.col-xs-7,
	.col-sm-7,
	.col-md-7,
	.col-lg-7,
	.col-xs-8,
	.col-sm-8,
	.col-md-8,
	.col-lg-8,
	.col-xs-9,
	.col-sm-9,
	.col-md-9,
	.col-lg-9,
	.col-xs-10,
	.col-sm-10,
	.col-md-10,
	.col-lg-10,
	.col-xs-11,
	.col-sm-11,
	.col-md-11,
	.col-lg-11,
	.col-xs-12,
	.col-sm-12,
	.col-md-12,
	.col-lg-12 {
		padding-left: 10px;
		padding-right: 10px;
	}
	.largePanels {
		padding-left: 0;
	}
	.quickLinks {
		float: left;
	}
	.hospitals {
		clear: left;
	}
	div.hospitals ul li {
		width: 48.7%;
		font-size: 90%;
		margin-right: 18px;
	}
	.childList {
		padding-left: 0;
	}
	.breadcrumb {
		margin-left: 10px;
		margin-right: 10px;
	}
	.headsearch {
		z-index: 100;
		padding: 0 0 0 2px;
	}
	.nearestsearchparas {
		padding: 0 10px;
	}
	button {
		margin: 10px auto 10px auto;
		width: 91%;
	}
	.topSection .megaDropDown {
		left: -10px;
		width: 102.5%;
	}
	.headbanner {
		padding-left: 0;
		padding-right: 0;
	}
	.topSection .navitem {
		min-height: 80px;
	}
	.postcodeHolder input {
		width: 120px;
	}
	.hospitals ul li a {
		font-size: 95%;
	}
	.col-xs-6 {
		width: 50%;
		padding-right: 0;
	}
	.col-xs-12 {
		width: 100%;
		padding-right: 0;
	}
	.logo {
		margin: 30px 10px;
	}
	.gateway .carousel {
		margin-left: 0;
	}
	.hospitalsideholder {
		padding-left: 10px;
		padding-right: 5px;
	}
	.hospitalholder {
		padding-left: 0;
	}
	.sidecontent {
		width: 100%;
	}
	.textpanel a.readmore,
	span.readmore {
		display: block;
		text-decoration: none;
		padding: 10px 10px;
	}
	div.hospitals ul.toplines li:nth-child(3),
	div.hospitals ul.toplines li:nth-child(4) {
		display: none;
	}
	ul.menuTrail {
		padding-left: 20px;
	}
	.half-image-half-text span.panelicon .fa {
		position: absolute;
		top: 17%;
		left: 13%;
	}
	.half-image-half-text span.panelicon {
		display: block;
		width: 100%;
		height: 130px;
	}
	.gateway .extend {
		padding-left: 0;
	}
	.logoholder {
		margin-top: -20px;
		width: 20%;
	}
	div.hospitals ul li:nth-child(even) {
		margin-right: 0;
	}
	.searchhosp {
		width: 100%;
		margin-top: 20px;
	}
	.searchhosp .select-style {
		margin-right: 20px;
	}
	.searchhosp .gobutton {
		width: 22%;
	}
	.paneltitle {
		padding: 10px 0 10px 10px;
	}
	.homepageAccordion .accContent .container {
		padding-left: 0;
	}
	.Nearest {
		margin: 0 auto;
		padding-right: 15px;
	}
	.container {
		padding-left: 0;
		padding-right: 0;
	}
	.openaccord,
	.closeaccord {
		margin-right: 0;
	}
	.sidecolumn .panel-holder {
		width: 50%;
		padding-left: 17px;
	}
	.sidecolumn div.subnav {
		width: 50%;
		float: left;
		padding-left: 0;
	}
	.sidecolumn {
		padding-left: 0;
	}
	.sidecolumn div.sidenav-holder {
		width: 50%;
		float: left;
		padding-left: 17px;
	}
	.hpPanelRow h3 {
		padding-left: 0;
	}
	.searchcontainer,
	.bannercontainer {
		min-height: 60px;
		margin-top: 0;
		/*-70px;*/
	}
	.searchcontainer form input {
		width: 76%;
	}
	.searchcontainer form {
		top: 10px;
		margin-top: 0;
		float: left;
	}
	.main {
		padding-left: 10px;
		padding-right: 10px;
	}
	.homepage .carousel {
		margin-left: 0;
	}
	ul.searchlinks {
		margin-bottom: 0;
	}
	.healthAlert .Alerttext {
		width: 44%;
	}
}

@media (max-width: 763px) {
	div.hospitals ul li {
		width: 46%;
	}
	.healthAlert .alerticon {
		display: none;
	}
	.healthAlert .Alerttext {
		width: 56%;
	}
	.gateway .inner h1 {
		padding-left: 10px;
	}
}

@media (max-width: 690px) {
	.healthAlert .Alerttitle {
		font-size: 1em;
		height: 60px;
	}
}

@media (max-width: 675px) {
	div.hospitals ul {
		width: 98%;
	}
	ul.menuTrail {
		padding-left: 10px;
	}
	div.hospitals p {
		margin-left: 12px;
	}
	div.hospitals ul li {
		width: 47.5%;
		line-height: 1.9em;
		background-position: right;
	}
	.hospitalsideholder {
		width: 94%;
		margin: 15px auto;
		float: none;
		padding-right: 0;
		padding-left: 0;
	}
	.Nearest {
		margin: -10px auto 20px;
	}
	#myCarousel,
	.gateway .carousel {
		display: none;
	}
	.mobiletitle {
		font-size: 200%;
	}
	.bannercontainer {
		border-left: none;
	}
	.logocolumn {
		border-right: 2px solid #fff;
		padding: 20px;
		display: none;
		margin-top: 0;
		width: 5%;
	}
	.footernav {
		width: 25%;
		min-height: 300px;
	}
	.breadcrumb {
		margin-left: 10px;
	}
	.inner {
		padding-left: 0;
		padding-right: 0;
	}
	.detailheader p {
		padding: 5px 0;
	}
	dl dl.wards dt strong,
	dl dl.wards dt.active strong {
		padding-left: 5px;
		width: 55%;
		float: left;
	}
	dt#Head {
		padding-left: 5px;
	}
	dl dl.wards dd,
	dl dl.services dd {
		padding: 5px;
	}
	.locationAddress .detailheader {
		width: 50%;
		min-height: 135px;
	}
}

@media (max-width: 560px) {
	.mobiletitle {
		font-size: 170%;
	}
	.mobiletitle img {
		top: 0;
	}
	.footernav {
		width: 100%;
		min-height: 100%;
	}
}

@media (max-width: 625px) {
	.half-image-half-text span.panelicon .fa {
		position: absolute;
		top: 20%;
		left: 13%;
	}
	.half-image-half-text span.panelicon {
		display: block;
		width: 100%;
		height: 145px;
	}
	.healthAlert .Alerttext {
		width: 35%;
		font-size: 1em;
		min-height: 60px;
	}
	.healthAlert .Alerttitle {
		font-size: 1em;
		height: 60px;
	}
	.healthAlert .alerticon {
		height: 60px;
	}
}

@media (max-width: 525px) {
	.healthAlert .Alerttext {
		width: 93%;
		font-size: 1em;
		min-height: 60px;
		margin-left: 20px;
		margin-top: 0;
	}
	.healthAlert .Alerttitle {
		font-size: 1em;
		height: 65px;
		width: 94%;
		margin-bottom: 5px;
	}
	.healthAlert .alerticon {
		height: 60px;
		margin-top: 0;
		margin-bottom: 5px;
		float: right;
	}
}

@media (max-width: 510px) {
	dl dl.wards dt,
	dl dl.services dt {
		padding: 5px 0;
	}
	dl dl.wards dt strong,
	dl dl.wards dt.active strong {
		padding-left: 5px;
		width: 90%;
		float: left;
	}
	dl.hospital dt.tabs {
		padding: 5px;
		line-height: 1.4em;
	}
	dl.hospital dt .wardTelephone {
		float: left;
		padding-left: 5px;
		width: 100%;
	}
	dl dl.services dt strong,
	dl dl.services dt.active strong {
		padding-left: 5px;
		width: 100%;
		float: left;
	}
	dt#Head .wardTelephone {
		display: none;
	}
}

@media (max-width: 500px) {
	div.hospitals ul {
		display: none;
		width: 98%;
	}
	.hospitalsideholder .sidecontent h3.paneltitle span.openCloseHolder {
		display: block;
	}
	.Nearest .openaccord {
		background: url(/img/openarrow_white.png) no-repeat;
		background-position: right;
	}
	.Nearest .closeaccord {
		background: url(/img/closearrow_white.png) no-repeat;
		background-position: right;
	}
	.largePanels {
		padding-right: 0;
	}
	.hospitalholder p.hTitle .openaccord {
		background: url(/img/openarrow_trans.png) no-repeat;
		background-position: right;
		cursor: pointer;
	}
	.hospitalholder p.hTitle .closeaccord {
		background: url(/img/closearrow_trans.png) no-repeat;
		background-position: right;
		cursor: pointer;
	}
	.hospitalholder p.hTitle {
		margin: 0;
		cursor: pointer;
		width: 100%;
		padding: 0;
		font-size: 1.3em;
		letter-spacing: 0.5px;
		color: #000;
		font-weight: 500;
	}
	.hospitalholder p.hTitle span.openCloseHolder {
		z-index: 2;
		margin-top: 4px;
		margin-right: 0;
		float: right;
		font-size: 12px;
		text-decoration: underline;
	}
	.sidecontent .paneltitle {
		background-image: none;
		cursor: pointer;
	}
	.homepageAccordion .accContent {
		padding-right: 0;
	}
	.childList .promo,
	.quickLinks .promo {
		min-height: 50px;
		height: auto !important;
	}
	.childList .promoarea .promo p,
	.quickLinks .promoarea .promo p {
		padding: 7px 40px 6px 7px;
	}
	.footernav {
		width: 50%;
		min-height: 220px;
	}
	div.hospitals p {
		margin-left: 8px;
	}
	.sidecontent {
		width: 98%;
		margin: 0 auto;
	}
	.openaccord,
	.closeaccord {
		margin-top: -27px;
	}
	.footer .container.header {
		padding-right: 0;
	}
	.col-xxs-12 {
		width: 98%;
		padding-right: 0;
	}
	.hpPanelRow h3 {
		margin: 14px 0;
	}
	.textpanel p {
		font-size: 1.16em;
	}
	.searchcontainer form {
		width: 90%;
		position: unset;
		z-index: 100;
		top: -10px;
		margin-bottom: 10px;
		margin-left: 20px;
		float: left;
	}
	.searchcontainer form input {
		width: 70%;
	}
	.mobiletitle {
		font-size: 140%;
	}
	.largePanels .panel {
		min-height: 350px;
		height: 345px;
	}
	ul.newsLatest img {
		display: none;
	}
	div.hospitals ul li {
		width: 98%;
	}
	.col-xxs-12.promoarea {
		width: 100%;
	}
	.promo {
		width: 100%;
	}
	.sidecolumn div.sidenav-holder {
		width: 98%;
		padding-left: 10px;
	}
	.sidecolumn .panel-holder {
		padding-right: 0;
		width: 98%;
		padding-left: 10px;
	}
	.promoarea .promo p {
		padding: 7px;
	}
	.fullimage img {
		width: auto;
	}
}

@media (max-width: 460px) {
	dl.hospital dt.tabs {
		font-size: 15px;
	}
	.half-image-half-text span.panelicon .fa {
		position: absolute;
		top: 15%;
		left: 13%;
	}
	.half-image-half-text span.panelicon {
		display: block;
		width: 100%;
		height: 130px;
	}
	.healthAlert .Alerttext {
		width: 93%;
		font-size: 1em;
		min-height: 60px;
		margin-left: 10px;
		margin-top: 0;
	}
	.healthAlert .alerticon {
		display: none;
	}
	.healthAlert .Alerttitle {
		margin-left: 10px;
		margin-top: 10px;
	}
}

@media (max-width: 440px) {
	dl.hospital dt.tabs {
		font-size: 13px;
	}
}

@media (max-width: 425px) {
	.mobiletitle {
		font-size: 18px;
	}
	h3 {
		margin: 14px 0 13px 0;
		font-size: 1.3em;
	}
	.openaccord,
	.closeaccord {
		font-size: 12px;
	}
}

@media (max-width: 400px) {
	.extend {
		padding-left: 0;
	}
	div.hospitals ul li {
		width: 96%;
	}
	dl.hospital dt.tabs {
		font-size: 11px;
	}
}

@media (max-width: 370px) {
	.childList {
		float: left;
	}
	.mobiletitle {
		font-size: 16px;
		font-family: verdana;
		padding-left: 0;
	}
	ul.searchlinks li a {
		padding: 0 4px;
	}
	.largePanels .panel {
		height: auto;
		min-height: 345px;
	}
}

.mobilenav {
	list-style: none;
	*zoom: 1;
	background: #175e4c;
}

.mobilenav:before,
.mobilenav:after {
	content: " ";
	display: table;
}

.mobilenav:after {
	clear: both;
}

.mobilenav ul {
	list-style: none;
	width: 9em;
}

.mobilenav a {
	padding: 10px 15px;
	color: #fff;
}

.mobilenav li {
	position: relative;
}

.mobilenav>li {
	float: left;
	border-top: 1px solid #104336;
}

.mobilenav>li>.parent {
	background-image: url("images/downArrow.png");
	background-repeat: no-repeat;
	background-position: right;
}

.mobilenav>li>a {
	display: block;
}

.mobilenav li ul {
	position: absolute;
	left: -9999px;
}

.mobilenav>li.hover>ul {
	left: 0;
}

.mobilenav li li.hover ul {
	left: 100%;
	top: 0;
}

.mobilenav li li a {
	display: block;
	background: #1d7a62;
	position: relative;
	z-index: 100;
	border-top: 1px solid #175e4c;
}

.mobilenav li li li a {
	background: #249578;
	z-index: 200;
	border-top: 1px solid #1d7a62;
}

@media screen and (max-width: 768px) {
	.mobile .nav {
		list-style: none;
		background: #fff;
		width: 100%;
		margin-top: 10px;
		margin-left: -10px;
	}
	.childList {
		margin-left: -10px;
	}
	.quickLinks {
		margin-left: -20px;
	}
	.active {
		display: block;
	}
	.mobile {
		padding-left: 20px;
	}
	.mobilenav>li {
		float: none;
	}
	.mobilenav>li>.parent {
		background-position: 95% 50%;
	}
	.mobilenav li li .parent {
		background-image: url("images/downArrow.png");
		background-repeat: no-repeat;
		background-position: 95% 50%;
	}
	.mobilenav ul {
		display: block;
		width: 100%;
	}
	.mobilenav>li.hover>ul,
	.mobilenav li li.hover ul {
		position: static;
	}
	.gateway .main {
		margin-left: 0;
	}
}

.content_default .AltColour {
	background: #e9e9e9;
}

.trans {
	background: transparent;
}

.homepageAccordion .accContent {
	display: none;
	padding-top: 5px;
}

.carousel-inner {
	height: 340px;
}

ul.searchResults li.promoted {
	background: #f3f3f3;
	margin-bottom: 10px;
	padding: 10px;
	border-bottom: none;
	font-weight: bold;
	float: left;
	width: 100%;
}

ul.searchResults li.promoted a {
	font-size: 18px;
}

ul.searchResults li.promoted p {
	font-size: 15px;
	font-weight: normal;
}

ul.searchResults li.promoted div {
	float: left;
}

ul.searchResults li.promoted .promo-thumb {
	width: 30%;
}

ul.searchResults li.promoted .promo-content {
	width: 69%;
}

.newsSearchResults .newsSearchImage {
	float: right;
	width: 100px;
}

.newsSearchResults a {
	text-decoration: none;
}

.newsSearchResults span.para {
	color: black;
}

.docLibList button {
	width: 150px;
}

.docLibList li img {
	margin-right: 0;
}

.hospitalsideholder .sidecontent {
	margin-bottom: 0;
	min-height: 100%;
}

.hospitalsideholder .sidecontent h3.paneltitle span.openCloseHolder {
	z-index: 2;
	margin-top: 4px;
	margin-right: 2px;
	float: right;
	font-size: 12px;
	text-decoration: underline;
}

.sideBarText p {
	padding: 15px;
}

.video-js {
	background-color: #fff !important;
}

.vjs-poster {
	left: 0px !important;
}

.vjs-tech {
	left: 0 !important;
}

.locationDetails .details {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;
	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.pubLibrary .search-results-folders .fa {
	position: relative;
	bottom: 0;
	left: 0;
	color: inherit;
	padding-right: 0.5em;
}

.pubLibrary li {
	list-style: none;
}

.dailymile {
	margin-bottom: 15px;
	background-color: #84b8f3;
	padding-bottom: 1em;
	padding: 1em;
	color: #fff;
	text-align: center;
	h4 {
		font-size: 5em;
		text-align: center;
		border-radius: 0.3em;
		margin: 20px 50px;
		border: 2px solid;
	}
}

.panel-padding {
	padding: 15px;
	input {
		max-width: 100%;
	}
}

.margin-top {
	margin-top: 30px;
}

.directWardServiceDetails {
	background-color: fade(@c-patients, 50%);
	border: 1px solid @c-patients;
	padding: 30px;
	margin-bottom: 30px;
	h2 {
		margin: 0 0 10px;
	}
}

.umbraco-forms-form {
	.checkboxlist {
		label {
			float: none !important;
			clear: none !important;
			position: relative;
			top: -2px;
		}
		input[type=checkbox] {
			width: 20px !important;
			height: 20px !important;
			margin: 0 5px 20px 0;
		}
	}
}

.imageLoadingDiv {
	position: relative;
	// min-height: 512px;
	padding-top: 100%;
	background: #fcfcfc;
	@media @desktop {
		margin-top: 30px;
	}
	.loading-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}
	img {
		max-width: 100%;
		width: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 2;
		display: block;
	}
}

span.quote-text {
	font-size: 2.6rem;
	// color: @c-main;
	line-height: 1.4;
	&:before {
		content: open-quote;
	}
	&:after {
		content: close-quote;
	}
}

.link--cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 50;
}

@import "modules/sitemap";
@import "modules/hr-grid";
@import "modules/loading";
@import "modules/twitter-panel";

/*# sourceMappingURL=nhsggc.css.map */